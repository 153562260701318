import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import FormGroup from "components/FormGroup";
// components
import GroupsList from "components/GroupsList";
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperGroupPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    groups: PropTypes.array,
    createGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    updateGroup: PropTypes.func,
    getGroupRoles: PropTypes.func,
    createGroupRole: PropTypes.func,
    deleteGroupRole: PropTypes.func,
    getGroupApps: PropTypes.func,
    createGroupApp: PropTypes.func,
    deleteGroupApp: PropTypes.func,
    organizations: PropTypes.array,
    getGroupOrganizations: PropTypes.func,
    createGroupOrganization: PropTypes.func,
    deleteGroupOrganization: PropTypes.func,
    roles: PropTypes.array,
    apps: PropTypes.array,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedGroup: undefined,
      formGroupOpen: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const { index } = this.state;
    const {
      groups,
      updateGroup,
      deleteGroup,
      roles,
      getGroupRoles,
      createGroupRole,
      deleteGroupRole,
      getGroupApps,
      deleteGroupApp,
      createGroupApp,
      getGroupOrganizations,
      getGroupById,
      createGroupOrganization,
      deleteGroupOrganization,
      organizations,
      apps,
      history,
    } = this.props;
    const { selectedGroup } = this.state;

    switch (index) {
      case 0:
        return (
          <div
            style={{ maxWidth: 1500, margin: "auto", width: "100%", flex: 1 }}
          >
            <GroupsList
              selectedGroup={selectedGroup}
              groups={groups}
              history={history}
              api={{
                updateGroup,
                deleteGroup,
                roles,
                getGroupRoles,
                getGroupById,
                createGroupRole,
                deleteGroupRole,
                getGroupApps,
                deleteGroupApp,
                createGroupApp,
                getGroupOrganizations,
                createGroupOrganization,
                deleteGroupOrganization,
              }}
              organizations={organizations}
              apps={apps}
              roles={roles}
            />
          </div>
        );
      default:
    }

    return [];
  }

  render() {
    const {
      loading,
      createGroup,
      deleteGroup,
      updateGroup,
      refresh,
      roles,
      getGroupRoles,
      createGroupRole,
      deleteGroupRole,
      getGroupApps,
      deleteGroupApp,
      createGroupApp,
      apps,
    } = this.props;

    const { formGroupOpen } = this.state;

    return (
      <Page
        helmet="Groups"
        loading={loading}
        loadingMessage={"Fetching all Groups"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formGroupOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
      >
        {this.getStepContent()}
        <FormGroup
          open={formGroupOpen}
          close={() => {
            this.setState({
              formGroupOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedGroup: undefined,
                }),
              200
            );
          }}
          createGroup={createGroup}
          deleteGroup={deleteGroup}
          updateGroup={updateGroup}
          roles={roles}
          apps={apps}
          getGroupRoles={getGroupRoles}
          createGroupRole={createGroupRole}
          deleteGroupRole={deleteGroupRole}
          getGroupApps={getGroupApps}
          deleteGroupApp={deleteGroupApp}
          createGroupApp={createGroupApp}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperGroupPage);
