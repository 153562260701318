// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// component
import WrapperOpaPage from "../component/WrapperOpaPage";

const mapStateToProps = (state) => ({
  roles: state.roles,
  serviceNames: state.serviceNames,
  rules: state.rules,
});

const mapDispatchToProps = (dispatch) => ({
  getServiceNames: (...args) => dispatch(Actions.getServiceNames(...args)),
  getRoles: (...args) => dispatch(Actions.getRoles(...args)),
  getRoleById: (...args) => dispatch(Actions.getRoleById(...args)),
  createRole: (...args) => dispatch(Actions.createRole(...args)),
  deleteRole: (...args) => dispatch(Actions.deleteRole(...args)),
  updateRole: (...args) => dispatch(Actions.updateRole(...args)),
  getRules: (...args) => dispatch(Actions.getRules(...args)),
  deleteRule: (...args) => dispatch(Actions.deleteRule(...args)),
  updateRule: (...args) => dispatch(Actions.updateRule(...args)),
  createRule: (...args) => dispatch(Actions.createRule(...args)),
  getServiceNameById: (...args) =>
    dispatch(Actions.getServiceNameById(...args)),
  createServiceName: (...args) => dispatch(Actions.createServiceName(...args)),
  deleteServiceName: (...args) => dispatch(Actions.deleteServiceName(...args)),
  updateServiceName: (...args) => dispatch(Actions.updateServiceName(...args)),
  importServiceName: (...args) => dispatch(Actions.importServiceName(...args)),
  exportServiceName: (...args) => dispatch(Actions.exportServiceName(...args)),
});
class OpaPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // serviceNames
    getServiceNames: PropTypes.func,
    serviceNames: PropTypes.array,

    // roles
    roles: PropTypes.array,
    getRoles: PropTypes.func,
    getRoleById: PropTypes.func,
    createRole: PropTypes.func,
    deleteRole: PropTypes.func,
    updateRole: PropTypes.func,

    // rules
    rules: PropTypes.func,
    getRules: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,
    createRule: PropTypes.func,

    // serviceNames
    getServiceNameById: PropTypes.func,
    createServiceName: PropTypes.func,
    deleteServiceName: PropTypes.func,
    updateServiceName: PropTypes.func,
    importServiceName: PropTypes.func,
    exportServiceName: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, roles } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: roles.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getRoles, getServiceNames, getRules } = this.props;
    await Promise.all([getRoles(), getServiceNames(), getRules()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      roles,
      history,
      rules,
      getRules,
      deleteRule,
      updateRule,
      createRule,
      getRoles,
      getRoleById,
      createRole,
      deleteRole,
      updateRole,
      serviceNames,
      getServiceNameById,
      createServiceName,
      deleteServiceName,
      updateServiceName,
      importServiceName,
      exportServiceName,
    } = this.props;

    const api = {
      getRules,
      deleteRule,
      updateRule,
      createRule,
      getRoles,
      getRoleById,
      createRole,
      deleteRole,
      updateRole,
      serviceNames,
      getServiceNameById,
      createServiceName,
      deleteServiceName,
      updateServiceName,
      importServiceName,
      exportServiceName,
    };

    const { urlParams, loading } = this.state;

    return (
      <WrapperOpaPage
        refresh={this.refresh.bind(this)}
        urlParams={urlParams}
        history={history}
        loading={loading}
        roles={roles}
        rules={rules}
        serviceNames={serviceNames}
        api={api}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OpaPage);
