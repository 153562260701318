import {
  Avatar,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Skeleton from "@material-ui/lab/Skeleton";
import App from "components/App";
import { getJsonFromUrl } from "helpers";
import CopyLabel from "hooks/CopyLabel";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function GroupDetail({
  group,
  api,
  loading,
  roles,
  apps,
  organizations,
  NotificationCenter,
}) {
  // const [ state, setState ] = React.useState(initialValue);
  const [name, setName] = React.useState(group.name);
  const [groupRoles, setGroupRoles] = React.useState(group.roles || []);
  const [groupRolesLoading, setGroupRolesLoading] = React.useState(false);

  const [groupApps, setGroupApps] = React.useState(group.apps || []);
  const [groupAppLoading, setGroupAppLoading] = React.useState(false);

  const [groupOrganizations, setGroupOrganizations] = React.useState(
    group.organizations || []
  );
  const [
    groupOrganizationsLoading,
    setGroupOrganizationsLoading,
  ] = React.useState(false);

  const [init, setInit] = React.useState(false);
  const [defaultGroup, setDefaultGroup] = React.useState(group.default);
  const classes = useStyles(styles);

  React.useEffect(() => {
    setName(group.name);
    setDefaultGroup(group.default);
    if (!init) {
      setInit(true);
      setGroupRoles([]);
      setGroupOrganizations([]);
      setGroupApps([]);

      setGroupRolesLoading(true);
      api.getGroupRoles(group.id).then((r) => {
        setGroupRoles(r.payload);
        setGroupRolesLoading(false);
      });

      setGroupAppLoading(true);
      api.getGroupApps(group.id).then((r) => {
        setGroupApps(r.payload);
        setGroupAppLoading(false);
      });

      setGroupOrganizationsLoading(true);
      api.getGroupOrganizations(group.id).then((r) => {
        setGroupOrganizations(r.payload);
        setGroupOrganizationsLoading(false);
      });
    }
  }, [group, api]);

  const save = (name, value, needRefresh) => async () => {
    await api.updateGroup(group.id, {
      [name]: value,
    });

    if (needRefresh) {
      const urlParams = getJsonFromUrl(window.location);
      api.getGroups(
        urlParams && urlParams.filters ? JSON.parse(urlParams.filters) : []
      );
    }
  };

  const deleteGroupApp = async (app) => {
    setGroupAppLoading(true);
    await api.deleteGroupApp(app.groupAppID);
    api.getGroupApps(group.id).then((r) => {
      setGroupApps(r.payload);
      setGroupAppLoading(false);
    });
  };

  const addGroupApp = async (app) => {
    setGroupAppLoading(true);
    await api.createGroupApp({ appID: app.id, groupID: group.id });
    api.getGroupApps(group.id).then((r) => {
      setGroupApps(r.payload);
      setGroupAppLoading(false);
    });
  };

  const deleteGroupRole = async (role) => {
    setGroupRolesLoading(true);
    await api.deleteGroupRole(group.id, role.id);
    api.getGroupRoles(group.id).then((r) => {
      setGroupRoles(r.payload);
      setGroupRolesLoading(false);
    });
  };

  const addGroupRole = async (role) => {
    setGroupRolesLoading(true);
    await api.createGroupRole(group.id, { roleID: role.id });
    api.getGroupRoles(group.id).then((r) => {
      setGroupRoles(r.payload);
      setGroupRolesLoading(false);
    });
  };

  const deleteGroupOrganization = async (organization) => {
    setGroupOrganizationsLoading(true);
    await api.deleteGroupOrganization(organization.relationID);
    api.getGroupOrganizations(group.id).then((r) => {
      setGroupOrganizations(r.payload);
      setGroupOrganizationsLoading(false);
    });
  };

  const addGroupOrganization = async (organization) => {
    setGroupOrganizationsLoading(true);
    await api.createGroupOrganization({
      organizationID: organization.id,
      groupID: group.id,
    });
    api.getGroupOrganizations(group.id).then((r) => {
      setGroupOrganizations(r.payload);
      setGroupOrganizationsLoading(false);
    });
  };

  const confirmDelete = async () => {
    const resp = await api.deleteGroup(group.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "User has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      const urlParams = getJsonFromUrl(window.location);
      api.getGroups(
        urlParams && urlParams.filters ? JSON.parse(urlParams.filters) : []
      );
      setTimeout(NotificationCenter.hide, 1500);
    }
  };

  const deleteRequest = () => {
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this group, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: confirmDelete,
        },
      }
    );
  };

  if (loading) {
    return (
      <Grid
        container
        style={{ textAlign: "left", minHeight: 540 }}
        justify="space-between"
        spacing={2}
      >
        <Grid item>
          <Skeleton style={{ width: 200, height: 30 }} variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 100 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 220 }} />
            <Skeleton variant="text" style={{ width: 198 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 202 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
        </Grid>
        <Grid xs={12} style={{ marginBottom: 8 }}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <Skeleton variant="rect" style={{ borderRadius: 80, width: 80 }} />
          <Skeleton
            variant="rect"
            style={{ borderRadius: 80, width: 120, marginLeft: 80 }}
          />
        </Grid>
      </Grid>
    );
  }

  const roleUserCanAdd = roles
    ? roles.filter(
        (r) => groupRoles.find((gr) => gr.roleID === r.id) === undefined
      )
    : [];

  const appUserCanAdd = apps
    ? apps.filter((a) => groupApps.find((gr) => gr.id === a.id) === undefined)
    : [];

  const organizationsUserCanAdd = organizations
    ? organizations.filter(
        (o) => groupOrganizations.find((go) => go.id === o.id) === undefined
      )
    : [];

  return (
    <Grid
      container
      style={{ textAlign: "left" }}
      justify="space-between"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"Id: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel label={`${group.id}`} />
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} style={{ marginBottom: 8 }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={save("name", name, true)}
          label="Name"
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={defaultGroup}
              color="primary"
              onChange={(e) => {
                setDefaultGroup(e.target.checked);
                save("default", e.target.checked, true);
              }}
              value="default"
            />
          }
          label="Default Group"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {roleUserCanAdd.length > 0 && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="age-simple">Add Role to Group</InputLabel>
            <Select
              inputProps={{
                name: "Add Role to Group",
                id: "role-simple",
              }}
              onChange={(e) => addGroupRole(e.target.value)}
            >
              {roleUserCanAdd.map((r) => (
                <MenuItem key={`role_${r.id}`} value={r}>
                  <span
                    style={{
                      background: r.serviceColor,
                      color: "white",
                      borderRadius: 12,
                      padding: "2px 8px 2px 8px",
                    }}
                  >
                    {`${r.serviceName}:${r.name}`}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {groupRolesLoading ? (
          <CircularProgress size={30} />
        ) : (
          <div>
            {groupRoles &&
              groupRoles.map((r) => (
                <Chip
                  key={`chip_${r.id}`}
                  className={classes.chip}
                  style={{
                    color: "white",
                    background: r.serviceColor,
                    margin: "8px 8px 8px 0px",
                  }}
                  label={`${r.serviceName}:${r.roleName}`}
                  onDelete={() => deleteGroupRole(r)}
                />
              ))}
            {groupRoles && groupRoles.length === 0 && (
              <Typography display="block" variant="caption">
                No Roles
              </Typography>
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {appUserCanAdd.length > 0 && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="age-simple">Add App to Group</InputLabel>
            <Select
              inputProps={{
                name: "Add App to Group",
                id: "App-simple",
              }}
              onChange={(e) => addGroupApp(e.target.value)}
            >
              {appUserCanAdd.map((a) => (
                <MenuItem key={`app_${a.id}`} value={a}>
                  <Chip
                    avatar={
                      <Avatar style={{ background: "#424242" }}>
                        <App {...a} size={20} />
                      </Avatar>
                    }
                    key={`chipApp_${a.id}`}
                    className={classes.chip}
                    style={{
                      color: "white",
                      background: "#424242",
                    }}
                    label={`${a.name}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {groupAppLoading ? (
          <CircularProgress size={30} />
        ) : (
          <Grid container>
            {groupApps &&
              groupApps.map((a) => (
                <Chip
                  avatar={
                    <Avatar style={{ background: "#424242" }}>
                      <App {...a} size={20} />
                    </Avatar>
                  }
                  key={`chipApp_${a.id}`}
                  className={classes.chip}
                  style={{
                    color: "white",
                    background: "#424242",
                    margin: "8px 8px 8px 0px",
                  }}
                  label={`${a.name}`}
                  onDelete={() => deleteGroupApp(a)}
                />
              ))}
            {groupApps && groupApps.length === 0 && (
              <Typography display="block" variant="caption">
                No Apps
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {organizationsUserCanAdd.length > 0 && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="age-simple">
              Add Organization to Group
            </InputLabel>
            <Select
              inputProps={{
                name: "Add Organization to Group",
                id: "Organization-simple",
              }}
              onChange={(e) => addGroupOrganization(e.target.value)}
            >
              {organizationsUserCanAdd.map((o) => (
                <MenuItem key={`orga_${o.id}`} value={o}>
                  {o.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <br />
        <br />
        {groupOrganizationsLoading ? (
          <CircularProgress size={30} />
        ) : (
          <div>
            {groupOrganizations &&
              groupOrganizations.map((o) => (
                <Chip
                  key={`chip_orga_${o.id}`}
                  className={classes.chip}
                  label={o.name}
                  onDelete={() => deleteGroupOrganization(o)}
                />
              ))}
            {groupOrganizations && groupOrganizations.length === 0 && (
              <Typography display="block" variant="caption">
                No Organizations
              </Typography>
            )}
          </div>
        )}
      </Grid>
      <Grid item>
        <Chip
          label="Delete Group"
          onClick={deleteRequest}
          icon={<DeleteForeverIcon />}
          style={{ marginLeft: 8 }}
        />
      </Grid>
    </Grid>
  );
}
