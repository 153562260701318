// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import App from "components/App";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableApp extends Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <div
        style={{
          width: 50,
          height: 50,
        }}
      >
        <App {...datum} size={50} />
      </div>
    );
  }
}

export default withStyles(styles)(TableApp);
