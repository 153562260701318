import { networkAction } from "helpers/network/networkAction";

import {
  GET_SERVICE_NAMES,
  GET_SERVICE_NAME_BY_ID,
  DELETE_SERVICE_NAME,
  UPDATE_SERVICE_NAME,
  CREATE_SERVICE_NAME,
  EXPORT_SERVICE_NAME,
  IMPORT_SERVICE_NAME,
} from "constants/serviceNames";

import * as Api from "api";

export const getServiceNames = () => async (dispatch) =>
  networkAction(dispatch, GET_SERVICE_NAMES, Api.getServiceNames, []);

export const getServiceNameById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SERVICE_NAME_BY_ID, Api.getServiceNameById, [id]);

export const exportServiceName = (id) => async (dispatch) =>
  networkAction(dispatch, EXPORT_SERVICE_NAME, Api.exportServiceName, [id]);

export const importServiceName = (id, body) => async (dispatch) =>
  networkAction(dispatch, IMPORT_SERVICE_NAME, Api.importServiceName, [
    id,
    body,
  ]);

export const deleteServiceName = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SERVICE_NAME, Api.deleteServiceName, [id]);

export const createServiceName = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SERVICE_NAME, Api.createServiceName, [body]);

export const updateServiceName = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SERVICE_NAME, Api.updateServiceName, [
    id,
    body,
  ]);
