import { Fab, Snackbar, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
// material-ui
import { withStyles } from "@material-ui/styles";
import { FormApplication } from "components";
// custom
import App from "components/App";
import Page from "components/Page";
import { getGreetingTime } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperAppsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    userApps: PropTypes.array,
    user: PropTypes.object,
    createApp: PropTypes.func,
    deleteApp: PropTypes.func,
    updateApp: PropTypes.func,
    refresh: PropTypes.func,
  };

  state = {
    formApplicationOpen: false,
  };

  render() {
    const {
      userApps,
      user,
      theme,
      createApp,
      deleteApp,
      updateApp,
      refresh,
    } = this.props;

    const superAdmin =
      user &&
      user.services &&
      user.services.account.find((s) => s === "superadmin");

    return (
      <Page
        helmet="Apps"
        fab={
          superAdmin ? (
            <Fab
              color="primary"
              aria-label="Add"
              onClick={() => this.setState({ formApplicationOpen: true })}
            >
              <AddIcon />
            </Fab>
          ) : (
            []
          )
        }
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={2}
          style={{ flex: 1 }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <Typography
                variant="h6"
                style={{
                  fontSize: 26,
                  color: theme.palette.type === "dark" ? "white" : "black",
                }}
              >
                {getGreetingTime(moment())}, {user.firstName}
              </Typography>
            </div>
            {userApps.length === 0 ? (
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{ maxWidth: 600 }}
              >
                <Grid item style={{ padding: 20 }}>
                  <Skeleton
                    width={92}
                    height={92}
                    variant="rect"
                    style={{ borderRadius: "20%" }}
                  />
                </Grid>
                <Grid item style={{ padding: 20 }}>
                  <Skeleton
                    width={92}
                    height={92}
                    variant="rect"
                    style={{ borderRadius: "20%" }}
                  />
                </Grid>
                <Grid item style={{ padding: 20 }}>
                  <Skeleton
                    width={92}
                    height={92}
                    variant="rect"
                    style={{ borderRadius: "20%" }}
                  />
                </Grid>
                <Grid item style={{ padding: 20 }}>
                  <Skeleton
                    width={92}
                    height={92}
                    variant="rect"
                    style={{ borderRadius: "20%" }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{ maxWidth: 600 }}
              >
                {userApps.map((app) => (
                  <Grid item key={app.id}>
                    <div
                      style={{
                        padding: 20,
                      }}
                    >
                      <App
                        {...app}
                        size={92}
                        withName
                        jiggle
                        onSelect={(a) => {
                          this.setState({
                            selectedApp: a,
                            formApplicationOpen: true,
                          });
                        }}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
        <FormApplication
          open={this.state.formApplicationOpen}
          application={this.state.selectedApp}
          close={() => {
            this.setState({
              formApplicationOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedApp: undefined,
                }),
              200
            );
          }}
          createApp={createApp}
          deleteApp={deleteApp}
          updateApp={updateApp}
          refresh={refresh}
        />
        {superAdmin ? (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            message="Click + Alt on an app to edit"
          />
        ) : (
          []
        )}
      </Page>
    );
  }
}
export default withStyles(styles, { withTheme: true })(WrapperAppsPage);
