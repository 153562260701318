import { Button, DialogActions } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import PropTypes from 'prop-types';
// material-ui
// import { makeStyles } from "@material-ui/styles";
import RuleComponent from "components/Rule";
import React from "react";
// styles
// import styles from "./styles";

// const useStyles = makeStyles(styles);

export default function Rule({ rule, api, role, refresh, create, edit }) {
  const [open, setOpen] = React.useState(false);
  // const classes = useStyles(styles);

  const map = {
    GET_BY_ID: undefined,
    GET_ALL: undefined,
    DELETE: undefined,
    PUT: undefined,
    POST: undefined,
    UNRECOGNIZED: [],
  };
  const labels = {};
  for (const key in rule.methods) {
    if (rule.methods.hasOwnProperty(key)) {
      const e = rule.methods[key];

      if (e.method === "PUT" && e.path.indexOf("[0-9]+") !== -1 && !map.PUT) {
        map.PUT = e;
        labels.PUT = e.path;
      } else if (e.method === "POST" && !map.POST) {
        map.POST = e;
        labels.POST = e.path;
      } else if (
        e.method === "DELETE" &&
        e.path.indexOf("[0-9]+") !== -1 &&
        !map.DELETE
      ) {
        map.DELETE = e;
        labels.DELETE = e.path;
      } else if (
        e.method === "GET" &&
        e.path &&
        e.path.indexOf("[0-9]+") !== -1 &&
        !map.GET_BY_ID
      ) {
        map.GET_BY_ID = e;
        labels.GET_BY_ID = e.path;
      } else if (
        e.method === "GET" &&
        e.path &&
        e.path.indexOf("[0-9]+") === -1 &&
        !map.GET_ALL
      ) {
        map.GET_ALL = e;
        labels.GET_ALL = e.path;
      } else {
        map.UNRECOGNIZED.push(e);
        labels.UNRECOGNIZED = e.path;
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{ padding: 4, cursor: "pointer" }}
        onClick={handleClickOpen}
      >
        <Grid item xs={12}>
          <Typography>
            {labels.POST ||
              labels.GET_ALL ||
              labels.GET_BY_ID ||
              labels.PUT ||
              labels.DELETE ||
              labels.UNRECOGNIZED}
          </Typography>
        </Grid>
        <Grid item>
          <Chip
            label="GET All"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!map.GET_ALL) {
                create({
                  method: "GET",
                  path: `/${rule.key}`,
                });
              } else {
                edit(map.GET_ALL);
              }
            }}
            style={
              map.GET_ALL
                ? {
                    color: "white",
                    borderColor: "#4caf50",
                    background: "#4caf50",
                  }
                : { opacity: 0.5 }
            }
          />
        </Grid>
        <Grid item>
          <Chip
            label="GET By ID"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!map.GET_BY_ID) {
                create({
                  method: "GET",
                  path: `/${rule.key}/[0-9]+`,
                });
              } else {
                edit(map.GET_BY_ID);
              }
            }}
            style={
              map.GET_BY_ID
                ? {
                    color: "white",
                    borderColor: "#4caf50",
                    background: "#4caf50",
                  }
                : { opacity: 0.5 }
            }
          />
        </Grid>
        <Grid item>
          <Chip
            label="POST"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!map.POST) {
                create({
                  method: "POST",
                  path: `/${rule.key}`,
                });
              } else {
                edit(map.POST);
              }
            }}
            style={
              map.POST
                ? {
                    color: "white",
                    borderColor: "#2196f3",
                    background: "#2196f3",
                  }
                : { opacity: 0.5 }
            }
          />
        </Grid>
        <Grid item>
          <Chip
            label="PUT"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!map.PUT) {
                create({
                  method: "PUT",
                  path: `/${rule.key}/[0-9]+`,
                });
              } else {
                edit(map.PUT);
              }
            }}
            style={
              map.PUT
                ? {
                    color: "white",
                    borderColor: "#e91e63",
                    background: "#e91e63",
                  }
                : { opacity: 0.5 }
            }
          />
        </Grid>
        <Grid item>
          <Chip
            label="DELETE"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!map.DELETE) {
                create({
                  method: "DELETE",
                  path: `/${rule.key}/[0-9]+`,
                });
              } else {
                edit(map.DELETE);
              }
            }}
            style={
              map.DELETE
                ? {
                    color: "white",
                    borderColor: "#f44336",
                    background: "#f44336",
                  }
                : { opacity: 0.5 }
            }
          />
        </Grid>
        {map.UNRECOGNIZED && map.UNRECOGNIZED.length ? (
          <Grid item>
            <Chip
              label={`+ ${map.UNRECOGNIZED.length} Custom`}
              variant="outlined"
              size="small"
            />
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Dialog aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="alert-dialog-title">{rule.key}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              {rule.methods.map((r) => (
                <Grid item xs={12} key={r.id}>
                  <RuleComponent rule={r} refresh={refresh} {...api} />
                </Grid>
              ))}
              <Grid item>
                <Chip
                  label="+ Rule"
                  variant="outlined"
                  onClick={() =>
                    api
                      .createRule({ path: `/${rule.key}`, roleID: role.id })
                      .then(refresh)
                  }
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            <CloseIcon /> Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
