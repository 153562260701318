// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperEventsPage from "../component/WrapperEventsPage";

const mapStateToProps = (state) => ({
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  getEvents: (...args) => dispatch(Actions.getEvents(...args)),
  getEventById: (...args) => dispatch(Actions.getEventById(...args)),
  createEvent: (...args) => dispatch(Actions.createEvent(...args)),
  deleteEvent: (...args) => dispatch(Actions.deleteEvent(...args)),
  updateEvent: (...args) => dispatch(Actions.updateEvent(...args)),
  testEvent: (...args) => dispatch(Actions.testEvent(...args)),
});
class EventsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    events: PropTypes.array,
    getEvents: PropTypes.func,
    getEventById: PropTypes.func,
    createEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    updateEvent: PropTypes.func,

    testEvent: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, events } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: events.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getEvents } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getEvents(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      events,
      getEventById,
      createEvent,
      deleteEvent,
      updateEvent,
      getEvents,
      history,
      testEvent,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperEventsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        events={events}
        getEvents={getEvents}
        getEventById={getEventById}
        createEvent={createEvent}
        deleteEvent={deleteEvent}
        updateEvent={updateEvent}
        urlParams={urlParams}
        testEvent={testEvent}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
