import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "unity";
const searchPresets = `${REACT_APP_API_PREFIX}/${service}/searchPresets`;

export function getSearchPresets() {
  const url = `${searchPresets}`;
  return {
    method: GET,
    url,
  };
}

export function getSearchPresetById(id) {
  const url = `${searchPresets}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSearchPreset(id, body) {
  const url = `${searchPresets}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSearchPreset(id) {
  const url = `${searchPresets}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSearchPreset(body) {
  const url = `${searchPresets}`;
  return {
    method: POST,
    url,
    body,
  };
}
