import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Arrow from "@material-ui/icons/CompareArrows";
import People from "@material-ui/icons/People";
import Security from "@material-ui/icons/SecurityTwoTone";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
// reactor
import Rule from "components/Rule";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class RoleDetail extends Component {
  static propTypes = {
    classes: PropTypes.object,
    close: PropTypes.func,
    role: PropTypes.object,
    deleteRole: PropTypes.func,
    updateRole: PropTypes.func,
    serviceNames: PropTypes.array,
    getRules: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,
    createRule: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { role } = this.props;
    this.state = {
      ...role,
      index: 0,
      loading: false,
      roleRoles: [],
      roleApps: [],
      method: 0,
    };
    this.refresh(role);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.init(nextProps.role);
    }
  }

  init(role) {
    //eslint-disable-line
    this.setState({
      ...role,
      loading: false,
      roleRoles: [],
      roleApps: [],
    });
    this.refresh(role);
  }

  getStepContent(index) {
    const { classes, serviceNames, role, updateRule, deleteRule } = this.props;

    const {
      name,
      loading,
      rules,
      serviceNameID,
      deleteValidation,
      method,
    } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <TextField
                id="name"
                fullWidth
                label="Name"
                className={classes.textField}
                value={name}
                onChange={this.handleChange("name")}
                onBlur={() => this.save("name")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("name");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="serviceName-simple">
                  serviceName
                </InputLabel>
                <Select
                  value={serviceNameID}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      serviceNameID: !prevState.serviceNameID,
                    })).then(() => {
                      this.save("serviceNameID");
                    });
                  }}
                  inputProps={{
                    name: "serviceName",
                    id: "serviceName-simple",
                  }}
                >
                  {serviceNames.map((g) => (
                    <MenuItem key={g.id} value={g.id}>
                      <span
                        style={{
                          background: g.color,
                          color: "white",
                          borderRadius: 12,
                          padding: "2px 8px 2px 8px",
                        }}
                      >
                        {g.name}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        let filteredRules = rules;
        if (method === 1)
          filteredRules = rules.filter((r) => r.method === "GET");
        if (method === 2)
          filteredRules = rules.filter((r) => r.method === "POST");
        if (method === 3)
          filteredRules = rules.filter((r) => r.method === "PUT");
        if (method === 4)
          filteredRules = rules.filter((r) => r.method === "DELETE");
        return (
          <div>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              style={{ width: 340 }}
              onChange={(e, m) => this.setState({ method: m })}
              value={method}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  wrapper: classes.wrapper,
                }}
              />
              <Tab
                label="GET"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="POST"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="PUT"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="DEL"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
            <Grid container spacing={2} className={classes.step}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {filteredRules &&
                    filteredRules.map((rule) => (
                      <Grid item xs={12} key={`rule_${rule.id}`}>
                        <Rule
                          rule={rule}
                          updateRule={updateRule}
                          deleteRule={deleteRule}
                          refresh={() => this.refresh(role)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item>
                <Chip label="New Rule" onClick={this.createRule.bind(this)} />
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Delete
              </Typography>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Enter the role's name to delete
                </Typography>
                <TextField
                  id="email"
                  variant="filled"
                  fullWidth
                  label={`${name}`}
                  className={classes.textField}
                  value={deleteValidation}
                  onChange={this.handleChange("deleteValidation")}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  style={{
                    opacity: deleteValidation !== name ? 0.5 : 1,
                  }}
                  size="small"
                  disabled={deleteValidation !== name}
                  onClick={this.deleteRole.bind(this)}
                >
                  Delete Role
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container>
            <Grid item>Unkown Step</Grid>
          </Grid>
        );
    }
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateRole, refresh, role } = this.props;

    await updateRole(role.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async createRule() {
    const { createRule, role } = this.props;
    const { method } = this.state;
    let ruleMethod = "";
    if (method === 1) ruleMethod = "GET";
    if (method === 2) ruleMethod = "POST";
    if (method === 3) ruleMethod = "PUT";
    if (method === 4) ruleMethod = "DELETE";

    await createRule({
      roleID: role.id,
      method: ruleMethod,
      path: "",
    });
    this.refresh(role);
  }

  async refresh(role) {
    const { getRules } = this.props;
    this.setState({ loading: true });
    const filters = [
      {
        name: "roleID",
        comparison: "eq",
        value: role.id,
      },
    ];

    const resp = await getRules(`?filters=${JSON.stringify(filters)}`);
    if (resp.success) {
      const rules = resp.payload;
      this.setState({ rules, loading: false });
    }
  }

  async deleteRole() {
    const { role, deleteRole, close, refresh } = this.props;
    await deleteRole(role.id);
    refresh();
    close();
  }

  render() {
    const { classes } = this.props;
    const { index, name, id } = this.state;

    return (
      <Grid container className={classes.container} direction="column">
        <Grid
          item
          style={{
            height: "calc(100vh - 57px)",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
          }}
        >
          <div
            style={{
              padding: 16,
              borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Security fontSize="large" />
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  {name}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  #{id}
                </Typography>
              </Grid>
            </Grid>
          </div>
          {this.getStepContent(index)}
        </Grid>
        <Grid item>
          <BottomNavigation
            value={index}
            onChange={(event, newIndex) => {
              this.setState({ index: newIndex });
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction label="General" icon={<People />} />
            <BottomNavigationAction label="Rules" icon={<Arrow />} />
            <BottomNavigationAction label="Danger" icon={<Warning />} />
          </BottomNavigation>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RoleDetail);
