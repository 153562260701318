export default (theme) => ({
  container: {
    width: 340,
    background: theme.palette.background.paper,
    height: "100vh",
    position: "relative",
  },
  step: {
    padding: 16,
  },
  badge: {
    margin: 4,
  },
  tabs: {
    background: "white",
  },
  tabsIndicator: {
    backgroundColor: theme.palette.text,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
    width: 340,
    marginRight: 0,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 50,
    width: "20%",
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.text,
      opacity: 1,
    },
    "&$tabSelected": {
      color: theme.palette.text,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.text,
    },
  },
  tabSelected: {},
});
