import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config/env";
import { getJsonFromUrl, updateURLParameter } from "helpers";
import UserDetail from "hooks/UserDetail";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class UsersList extends React.Component {
  static propTypes = {
    users: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
    organizations: PropTypes.array,
    groups: PropTypes.array,
    getUsers: PropTypes.func,
    api: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      selectedUser: undefined,
      userLoading: urlParams.userID,
    };

    if (urlParams.userID && this.state.selectedUser === undefined) {
      this.setState({ userLoading: true });
      this.props.api.getUserById(Number(urlParams.userID)).then((r) =>
        this.setState({
          selectedUser: r.payload,
          userLoading: false,
        })
      );
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const urlParams = getJsonFromUrl(window.location);

  //   if (urlParams.userID && this.state.selectedUser === undefined) {
  //     this.setState({ userLoading: true });
  //     const user = nextProps.api
  //       .getUserById(Number(urlParams.userID))
  //       .then((r) =>
  //         this.setState({
  //           selectedUser: r.payload,
  //           userLoading: false,
  //         })
  //       );
  //   }
  // }

  onSelect(selectedUser) {
    this.setState({
      selectedUser,
    });
  }

  render() {
    const {
      history,
      getUsers,
      users,
      organizations,
      api,
      apps,
      groups,
    } = this.props;

    const { NotificationCenter } = this.context;

    const { selectedUser, userLoading } = this.state;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={(...args) => {
          this.setState({ selectedUser: undefined });
          return getUsers(...args);
        }}
        rowCount={users.length}
        keyword="users"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/account/users/searchMetadata`}
        exportEndpoint={`${REACT_APP_API_PREFIX}/account/users/csv`}
        metaDataOveride={{
          organizationID: {
            type: "autocomplete",
            data: organizations,
            valueKey: "id",
            labelKey: "name",
          },
          uuid: {
            type: "autoFill",
            label: "User",
            getData: (search) =>
              api.searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Table
              data={users}
              rowStyle={(d) => ({
                opacity: d.active ? 1 : 0.5,
                background:
                  selectedUser && selectedUser.id === d.id
                    ? "rgba(33,150,243, 0.1)"
                    : undefined,
              })}
              meta={[
                {
                  path: "icon",
                  numeric: false,
                  width: 30,
                  render: (t, d) => (
                    <Avatar>
                      {d.firstName[0]}
                      {d.lastName[0]}{" "}
                    </Avatar>
                  ),
                },
                {
                  path: "lastName",
                  title: "Name",
                  numeric: false,
                  transform: (t, d) => `${d.firstName} ${d.lastName}`,
                  render: (t, d) => (
                    <>
                      <Typography variant="body2">{t}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {organizations.find((o) => o.id === d.organizationID)
                          ?.name ?? "-"}
                      </Typography>
                    </>
                  ),
                },
                {
                  path: "email",
                  title: "Email",
                  numeric: false,
                },
              ]}
              title={"Users"}
              onRowSelect={(e) => {
                this.setState({ selectedUser: e, userDetailOpen: true });
                api
                  .getUserById(e.id)
                  .then((r) => this.setState({ selectedUser: r.payload }));
                history.push({
                  search: updateURLParameter("userID", e.id),
                });
              }}
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={6}>
              {selectedUser ? (
                <Paper style={{ padding: 20 }}>
                  <UserDetail
                    user={selectedUser}
                    api={api}
                    apps={apps}
                    groups={groups}
                    history={history}
                    NotificationCenter={NotificationCenter}
                    loading={userLoading}
                  />
                </Paper>
              ) : (
                []
              )}
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Dialog
            open={this.state.userDetailOpen}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <UserDetail
                user={selectedUser}
                api={api}
                apps={apps}
                groups={groups}
                history={history}
                NotificationCenter={NotificationCenter}
                loading={userLoading}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => this.setState({ userDetailOpen: false })}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(UsersList);
