// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  Paper,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
// custom
import Table from "components/TablePagination";
import { getJsonFromUrl, updateURLParameter } from "helpers";
import GroupDetail from "hooks/GroupDetail";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

class GroupsList extends React.Component {
  static propTypes = {
    selectedGroup: PropTypes.object,
    groups: PropTypes.array,
    api: PropTypes.object,
    organizations: PropTypes.array,
    apps: PropTypes.array,
    roles: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      selectedGroup: undefined,
      groupLoading: urlParams.groupID,
    };

    if (urlParams.groupID && this.state.selectedGroup === undefined) {
      this.setState({ groupLoading: true });
      this.props.api.getGroupById(Number(urlParams.groupID)).then((r) =>
        this.setState({
          selectedGroup: r.payload,
          groupLoading: false,
        })
      );
    }
  }

  onSelect(selectedGroup) {
    this.setState({
      selectedGroup,
    });
  }

  render() {
    const {
      history,
      api,
      refresh,
      roles,
      organizations,
      groups,
      apps,
    } = this.props;

    const { selectedGroup, groupLoading } = this.state;

    const { NotificationCenter } = this.context;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Table
              data={groups}
              rowStyle={(d) => ({
                background:
                  selectedGroup && selectedGroup.id === d.id
                    ? "rgba(33,150,243, 0.1)"
                    : undefined,
              })}
              meta={[
                {
                  path: "name",
                  title: "Name",
                  numeric: false,
                },
              ]}
              title={"Groups"}
              onRowSelect={(e) => {
                this.setState({ selectedGroup: e, groupDetailOpen: true });
                history.push({
                  search: updateURLParameter("groupID", e.id),
                });
              }}
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={6}>
              {selectedGroup ? (
                <Paper style={{ padding: 20 }}>
                  <GroupDetail
                    group={selectedGroup}
                    key={(selectedGroup || groups[0]).id}
                    loading={groupLoading}
                    roles={roles}
                    apps={apps}
                    api={api}
                    organizations={organizations}
                    refresh={refresh}
                    history={history}
                    NotificationCenter={NotificationCenter}
                  />
                </Paper>
              ) : (
                []
              )}
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Dialog
            open={this.state.groupDetailOpen}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <GroupDetail
                group={selectedGroup || groups[0]}
                loading={groupLoading}
                key={(selectedGroup || groups[0]).id}
                roles={roles}
                apps={apps}
                api={api}
                organizations={organizations}
                refresh={refresh}
                history={history}
                NotificationCenter={NotificationCenter}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => this.setState({ groupDetailOpen: false })}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </>
    );
  }
}

export default withStyles(styles)(GroupsList);
