import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormAccessToken extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    accessToken: PropTypes.object,

    createAccessToken: PropTypes.func,
    updateAccessToken: PropTypes.func,
    deleteAccessToken: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.accessToken);
    }
  }

  init(accessToken) {
    this.setState({
      loading: false,
      name: accessToken ? accessToken.name : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this accessToken, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: () => console.log("Callback"),
        },
      }
    );
  }

  async save() {
    const {
      updateAccessToken,
      createAccessToken,
      accessToken,
      refresh,
    } = this.props;

    const { name } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (accessToken) {
      resp = await updateAccessToken(accessToken.id, {
        name,
      });
    } else {
      resp = await createAccessToken({
        name,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      this.setState({ resp });
      refresh();
      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, accessToken, close } = this.props;

    const { name, loading, resp } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {resp ? (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  Save your token
                </DialogTitle>
                <DialogContent>
                  <Typography display="block" variant="body2">
                    Make sure you save it - you won't be able to access it again
                    :
                  </Typography>
                  <Typography
                    display="block"
                    variant={"caption"}
                    classes={{
                      caption: classes.accessToken,
                    }}
                  >
                    {resp.payload.token}
                  </Typography>
                </DialogContent>
              </div>
            ) : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {accessToken
                    ? `${accessToken.name}`
                    : "Let's Create a New Access Token"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {accessToken
                      ? "To update the accessToken, fill the form below :"
                      : "To create a new accessToken, fill the form below :"}
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name *"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange("name")}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
            )}
            <DialogActions className={classes.dialogActions}>
              {accessToken ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              {resp === undefined && (
                <Button
                  color="primary"
                  autoFocus
                  onClick={this.save.bind(this)}
                >
                  <SaveIcon className={classes.leftIcon} />{" "}
                  {accessToken ? "Save" : "Create"}
                </Button>
              )}
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormAccessToken));
