import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "account";
const apps = `${REACT_APP_API_PREFIX}/${service}/apps`;

export function getApps() {
  const url = `${apps}`;
  return {
    method: GET,
    url,
  };
}

export function getAppById(id) {
  const url = `${apps}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateApp(id, body) {
  const url = `${apps}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteApp(id) {
  const url = `${apps}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createApp(body) {
  const url = `${apps}`;
  return {
    method: POST,
    url,
    body,
  };
}
