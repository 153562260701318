// import PropTypes from 'prop-types';
// material-ui
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisibilityOff from "@material-ui/icons/VisibilityOffTwoTone";
import Visibility from "@material-ui/icons/VisibilityTwoTone";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import App from "components/App";
// regexp
import {
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_8_CHAR,
} from "constants/regexp";
// creative-tim
import Badge from "creativeTim/Badge/Badge";
import { getJsonFromUrl } from "helpers";
import CopyLabel from "hooks/CopyLabel";
import PhoneInput from "hooks/PhoneInput";
import moment from "moment";
import React from "react";
import MaskedInput from "react-text-mask";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const phoneRegExp = /^((\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4})|(\d{11})$/;

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        "+",
        /\d/,
        " ",
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

export default function UserDetail({
  api,
  user,
  apps,
  groups,
  NotificationCenter,
  history,
  loading,
}) {
  const [firstName, setFirstName] = React.useState(user.firstName);
  const [active, setActive] = React.useState(user.active);
  const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);
  const [lastName, setLastName] = React.useState(user.lastName);
  const [organizationID, setOrganizationID] = React.useState(
    user.organizationID
  );
  const [organizations, setOrganizations] = React.useState(user.organizations);
  const [password, setPassword] = React.useState("");
  const [passwordLoading, setPasswordLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [
    showPasswordConfirmation,
    setShowPasswordConfirmation,
  ] = React.useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [email, setEmail] = React.useState(user.email);
  const [phone, setPhone] = React.useState(user.email);
  const [uuid, setUUID] = React.useState(user.uuid);
  const [userGroups, setUserGroups] = React.useState([]);
  const [groupLoading, setGroupLoading] = React.useState(false);
  const [defaultAppID, setDefaultAppID] = React.useState(user.defaultAppID);
  const classes = useStyles(styles);

  React.useEffect(() => {
    if (uuid) {
      setFirstName(user.firstName);
      setUUID(user.uuid);
      setLastName(user.lastName);
      setOrganizations(user.organizations);
      setEmail(user.email);
      setPhone(user.phone);
      setActive(user.active);
      setDefaultAppID(user.defaultAppID);
      setOrganizationID(user.organizationID);
      setActive(user.active);
      api.getUserGroups(user.id).then((r) => setUserGroups(r.payload));
    }
  }, [uuid, user]);

  const save = (name, value, needRefresh) => async () => {
    await api.updateUser(user.id, {
      [name]: value,
    });
    if (needRefresh) {
      const urlParams = getJsonFromUrl(window.location);
      api.getUsers(
        urlParams && urlParams.filters ? JSON.parse(urlParams.filters) : []
      );
    }
  };

  const getUserGroups = async (group) => {
    const r = await api.getUserGroups(user.id);
    setUserGroups(r.payload);
  };

  const deleteGroup = async (group) => {
    setGroupLoading(true);
    await api.deleteUserGroup(user.id, group.id);
    setGroupLoading(false);
    getUserGroups();
  };

  const addGroup = async (group) => {
    setGroupLoading(true);
    await api.createUserGroup(user.id, { groupID: group.id });
    setGroupLoading(false);
    getUserGroups();
  };

  const groupUserCanAdd = groups.filter(
    (g) => userGroups.find((ug) => ug.groupID === g.id) === undefined
  );

  const resetPassword = async () => {
    setPasswordLoading(true);
    const resp = await api.updateUserPassword(user.id, {
      password,
      passwordConfirmation,
    });
    setPasswordLoading(false);
    if (resp.success) {
      setPassword("");
      setPasswordConfirmation("");
      setShowPassword(false);
      setShowPasswordConfirmation(false);
      NotificationCenter.sweetAlert(
        {
          title: "Success !",
          subtitle: "The new password has been set.",
          success: true,
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );
      setResetPasswordOpen(false);
    }
  };

  const confirmDelete = async () => {
    const resp = await api.deleteUser(user.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "User has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      const urlParams = getJsonFromUrl(window.location);
      api.getUsers(
        urlParams && urlParams.filters ? JSON.parse(urlParams.filters) : []
      );
      setTimeout(NotificationCenter.hide, 1500);
    }
  };

  const deleteRequest = () => {
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this user, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: confirmDelete,
        },
      }
    );
  };

  if (loading) {
    return (
      <Grid
        container
        style={{ textAlign: "left", minHeight: 540 }}
        justify="space-between"
        spacing={2}
      >
        <Grid item>
          <Skeleton style={{ width: 200, height: 30 }} variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 100 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 220 }} />
            <Skeleton variant="text" style={{ width: 198 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 202 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
        </Grid>
        <Grid xs={12} style={{ marginBottom: 8 }}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <Skeleton variant="rect" style={{ borderRadius: 80, width: 80 }} />
          <Skeleton
            variant="rect"
            style={{ borderRadius: 80, width: 120, marginLeft: 80 }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      style={{ textAlign: "left" }}
      justify="space-between"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h6">
          {firstName} {lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Chip
          label="Impersonate"
          onClick={async () => {
            await api.impersonate(user);
            history.push("/");
          }}
          icon={<Visibility style={{ color: "white" }} />}
          style={{ color: "white", background: "#f44336" }}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"User Since: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel label={moment(user.createdAt).format("lll")} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"UUID: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel label={user.uuid} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"ID: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel label={user.id} />
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} style={{ marginBottom: 8 }}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onBlur={save("firstName", firstName, true)}
          label="First Name"
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onBlur={save("lastName", lastName, true)}
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          label="Last Name"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={save("email", email, true)}
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          label="Email"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onBlur={save("phone", phone, true)}
          style={{
            background: "rgba(155,155,155,0.1)",
          }}
          error={phone && !phoneRegExp.test(phone)}
          fullWidth
          InputProps={{ inputComponent: TextMaskCustom }}
          variant="outlined"
          label="Phone"
          size="small"
        />
        {phone && !phoneRegExp.test(phone) ? (
          <Typography style={{ color: "red" }} variant="caption">
            Wrong phone format
          </Typography>
        ) : (
          []
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={organizationID}
          onChange={(e) => {
            setOrganizationID(e.target.value);
            api.setOrganizationByDefault();
          }}
          // onBlur={save("organizationID", Number(organizationID), true)}
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          select
          variant="outlined"
          label="Current Organization"
          size="small"
        >
          {organizations?.map((o) => (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classes.formControl}
          fullWidth
          style={{ background: "rgba(155,155,155,0.1)" }}
          variant="outlined"
          size="small"
        >
          <InputLabel htmlFor="default-app">Default App</InputLabel>
          <Select
            inputProps={{
              name: "Default App<",
              id: "default-app",
            }}
            label="Default App<"
            value={defaultAppID}
            onChange={(e) => {
              setDefaultAppID(e.target.value);
              save("defaultAppID", e.target.value, false);
            }}
          >
            <MenuItem value={0}>
              <Chip
                className={classes.chip}
                style={{
                  color: "white",
                  background: "#9e9e9e",
                }}
                label={"None"}
              />
            </MenuItem>
            {apps &&
              apps.map((a) => (
                <MenuItem key={`app_${a.id}`} value={a.id}>
                  <Chip
                    avatar={
                      <Avatar style={{ background: "#424242" }}>
                        <App {...a} size={20} />
                      </Avatar>
                    }
                    key={`chipApp_${a.id}`}
                    className={classes.chip}
                    style={{
                      color: "white",
                      background: "#424242",
                    }}
                    label={`${a.name}`}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {user !== undefined && (
          <Grid item xs={12}>
            {groupUserCanAdd.length && (
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="group-simple">
                  Add Groups to Users
                </InputLabel>
                <Select
                  inputProps={{
                    name: "Add Groups to User",
                    id: "group-simple",
                  }}
                  onChange={(e) => addGroup(e.target.value)}
                >
                  {groupUserCanAdd.map((g) => (
                    <MenuItem key={`role_${g.id}`} value={g}>
                      {g.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <br />
            <br />
            {groupLoading ? (
              <CircularProgress size={30} />
            ) : (
              <div>
                {userGroups &&
                  userGroups.map((g) => (
                    <Chip
                      key={`chip_${g.id}`}
                      className={classes.chip}
                      label={g.groupName}
                      onDelete={() => deleteGroup(g)}
                    />
                  ))}
                {userGroups.length === 0 && (
                  <Typography display="block" variant="caption">
                    No Groups
                  </Typography>
                )}
              </div>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              color="primary"
              onChange={(e) => {
                setActive(e.target.checked);
                save("active", e.target.checked, true)();
              }}
              value="active"
            />
          }
          label="Email Verified (Active)"
        />
      </Grid>
      <Grid item>
        <Chip
          label="Send PL Code"
          onClick={() =>
            api.requestPLCode({ email }).then(() => {
              NotificationCenter.sweetAlert(
                {
                  title: "Success !",
                  subtitle:
                    "The password less code has been sent by email & SMS if available.",
                  success: true,
                  timestamp: new Date().getTime(),
                },
                {
                  confirm: {
                    label: "Ok",
                    level: "success",
                  },
                }
              );
            })
          }
          icon={<Icon fontSize="inherit" className="fad fa-wand-magic" />}
        />
        <Chip
          label="Reset Password"
          onClick={() => setResetPasswordOpen(true)}
          icon={<VpnKeyIcon />}
          style={{ marginLeft: 8 }}
        />
        <Chip
          label="Delete User"
          onClick={deleteRequest}
          icon={<DeleteForeverIcon />}
          style={{ marginLeft: 8 }}
        />
      </Grid>
      <Dialog
        open={resetPasswordOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change Password for {firstName} {lastName}
        </DialogTitle>
        <DialogContent>
          {passwordLoading ? (
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center" }}
            >
              <CircularProgress />
              <br />
              <Typography>Loading...</Typography>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2} className={classes.step}>
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.textField}>
                    <InputLabel htmlFor="adornment-password">
                      Password
                    </InputLabel>
                    <Input
                      id="adornment-password"
                      label="Password *"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex="-1"
                            aria-label="Toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.textField}>
                    <InputLabel htmlFor="adornment-password-retype">
                      Password Confirmation
                    </InputLabel>
                    <Input
                      type={showPasswordConfirmation ? "text" : "password"}
                      id="passwordConfirmation"
                      label="Confirmation Password *"
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex="-1"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation
                              )
                            }
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPasswordConfirmation ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Badge
                    color={
                      AT_LEAST_8_CHAR.test(password) ? "success" : "warning"
                    }
                  >
                    At least 8 characters
                  </Badge>
                  <Badge
                    color={
                      AT_LEAST_1_NUMERICAL_CHAR.test(password)
                        ? "success"
                        : "warning"
                    }
                    className={classes.badge}
                  >
                    At least 1 digit
                  </Badge>
                  <Badge
                    color={
                      AT_LEAST_1_ALPHABETICAL_CHAR.test(password)
                        ? "success"
                        : "warning"
                    }
                    className={classes.badge}
                  >
                    At least 1 letter
                  </Badge>
                  <Badge
                    color={
                      password === passwordConfirmation ? "success" : "warning"
                    }
                    className={classes.badge}
                  >
                    Passwords match
                  </Badge>
                </Grid>
              </Grid>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setResetPasswordOpen(false)}
            style={{ textTransform: "none " }}
          >
            Close
          </Button>
          {!passwordLoading ? (
            <Button
              onClick={resetPassword}
              disabled={
                !(
                  AT_LEAST_8_CHAR.test(password) &&
                  AT_LEAST_1_NUMERICAL_CHAR.test(password) &&
                  AT_LEAST_1_ALPHABETICAL_CHAR.test(password) &&
                  password === passwordConfirmation
                )
              }
              style={{ textTransform: "none " }}
              color="primary"
              autoFocus
              variant="contained"
            >
              Save
            </Button>
          ) : (
            []
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
