// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableApp from "components/TableApp";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ApplicationsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    applications: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedApplication) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedApplication);
    }
  }

  render() {
    const { applications } = this.props;

    return (
      <Table
        data={applications}
        meta={[
          {
            path: "id",
            title: "",
            numeric: false,
            component: TableApp,
            width: 40,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "url",
            title: "Url",
            numeric: false,
          },
        ]}
        title={"Applications"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ApplicationsList);
