// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAppsPage from "../component/WrapperAppsPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getUserApps: (...args) => dispatch(Actions.getUserApps(...args)),
  createApp: (...args) => dispatch(Actions.createApp(...args)),
  deleteApp: (...args) => dispatch(Actions.deleteApp(...args)),
  updateApp: (...args) => dispatch(Actions.updateApp(...args)),
});
class AppsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    userApps: PropTypes.array,
    getUserApps: PropTypes.func,
    user: PropTypes.object,
    apps: PropTypes.array,
    getApps: PropTypes.func,
    getAppById: PropTypes.func,
    createApp: PropTypes.func,
    deleteApp: PropTypes.func,
    updateApp: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    this.state = {
      urlParams: getJsonFromUrl(location),
    };
    this.refresh();
  }

  refresh() {
    const { getUserApps } = this.props;
    return getUserApps();
  }

  render() {
    const {
      location,
      history,
      userApps,
      user,
      createApp,
      deleteApp,
      updateApp,
    } = this.props;

    const { urlParams } = this.state;

    return (
      <WrapperAppsPage
        userApps={userApps}
        history={history}
        user={user}
        refresh={this.refresh.bind(this)}
        location={location}
        urlParams={urlParams}
        createApp={createApp}
        deleteApp={deleteApp}
        updateApp={updateApp}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppsPage);
