// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// component
import WrapperUsersPage from "../component/WrapperUsersPage";

const mapStateToProps = (state) => ({
  users: state.users,
  groups: state.groups,
  organizations: state.organizations,
  apps: state.apps,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizations: (...args) => dispatch(Actions.getOrganizations(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getUserById: (...args) => dispatch(Actions.getUserById(...args)),
  createUser: (...args) => dispatch(Actions.createUser(...args)),
  deleteUser: (...args) => dispatch(Actions.deleteUser(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),
  updateUserPassword: (...args) =>
    dispatch(Actions.updateUserPassword(...args)),
  getApps: (...args) => dispatch(Actions.getApps(...args)),

  getGroups: (...args) => dispatch(Actions.getGroups(...args)),
  getUserGroups: (...args) => dispatch(Actions.getUserGroups(...args)),
  createUserGroup: (...args) => dispatch(Actions.createUserGroup(...args)),
  deleteUserGroup: (...args) => dispatch(Actions.deleteUserGroup(...args)),
  impersonate: (...args) => dispatch(Actions.impersonate(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  setOrganizationByDefault: (...args) => dispatch(Actions.searchUsers(...args)),
  requestPLCode: (...args) => dispatch(Actions.requestPLCode(...args)),
});
class UsersPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    users: PropTypes.array,
    getUsers: PropTypes.func,
    getUserById: PropTypes.func,
    createUser: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,

    groups: PropTypes.array,
    apps: PropTypes.array,
    getGroups: PropTypes.func,
    getApps: PropTypes.func,
    searchUsers: PropTypes.func,
    getUserGroups: PropTypes.func,
    createUserGroup: PropTypes.func,
    deleteUserGroup: PropTypes.func,
    getOrganizations: PropTypes.func,
    requestPLCode: PropTypes.func,
    impersonate: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, users, getOrganizations } = this.props;
    getOrganizations();
    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: users.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getUsers, getGroups, getApps } = this.props;

    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getUsers(filters), getGroups(), getApps()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      users,
      getUserById,
      createUser,
      deleteUser,
      updateUser,
      updateUserPassword,
      organizations,
      groups,
      getUserGroups,
      createUserGroup,
      deleteUserGroup,
      history,
      impersonate,
      getUsers,
      searchUsers,
      setOrganizationByDefault,
      requestPLCode,
      apps,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperUsersPage
        refresh={this.refresh.bind(this)}
        searchUsers={searchUsers}
        getUsers={getUsers}
        history={history}
        loading={loading}
        organizations={organizations}
        requestPLCode={requestPLCode}
        users={users}
        getUserById={getUserById}
        createUser={createUser}
        deleteUser={deleteUser}
        setOrganizationByDefault={setOrganizationByDefault}
        updateUser={updateUser}
        updateUserPassword={updateUserPassword}
        urlParams={urlParams}
        groups={groups}
        getUserGroups={getUserGroups}
        createUserGroup={createUserGroup}
        deleteUserGroup={deleteUserGroup}
        apps={apps}
        impersonate={impersonate}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
