export default (theme) => ({
  container: {
    width: 340,
    background: theme.palette.background.paper,
    height: "100vh",
    position: "relative",
  },
  step: {
    padding: 16,
  },
  badge: {
    margin: 4,
  },
});
