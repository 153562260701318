export default (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  card: {
    maxWidth: 400,
    textAlign: "center",
    width: "100%",
    margin: "auto",
    background: theme.palette.background.paper,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  button: {
    margin: "auto",
  },
  iForgotButton: {
    padding: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  zoomContainer: {
    textAlign: "center",
  },
  signupButton: {
    marginTop: 20,
  },
  disabled: {
    color: "blue",
  },
});
