import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import FormUser from "components/FormUser";
// reactor
import Page from "components/Page";
// components
import UsersList from "components/UsersList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperUsersPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    users: PropTypes.array,
    apps: PropTypes.array,
    createUser: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,
    groups: PropTypes.array,
    getUserById: PropTypes.func,
    getUserGroups: PropTypes.func,
    createUserGroup: PropTypes.func,
    deleteUserGroup: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    impersonate: PropTypes.func,
    getUsers: PropTypes.func,
    requestPLCode: PropTypes.func,
    searchUsers: PropTypes.func,
    organizations: PropTypes.array,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedUser: undefined,
      formUserOpen: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const { index } = this.state;
    const {
      users,
      usersSearch,
      getUsers,
      history,
      organizations,
      deleteUser,
      updateUser,
      updateUserPassword,
      getUserGroups,
      getUserById,
      impersonate,
      createUserGroup,
      setOrganizationByDefault,
      deleteUserGroup,
      apps,
      searchUsers,
      groups,
      requestPLCode,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <div
            style={{ maxWidth: 1500, margin: "auto", width: "100%", flex: 1 }}
          >
            <UsersList
              selectedUser={this.state.selectedUser}
              api={{
                deleteUser,
                updateUser,
                updateUserPassword,
                impersonate,
                getUserGroups,
                createUserGroup,
                deleteUserGroup,
                getUserById,
                getUsers,
                searchUsers,
                setOrganizationByDefault,
                requestPLCode,
              }}
              apps={apps}
              groups={groups}
              usersSearch={usersSearch}
              getUsers={getUsers}
              history={history}
              organizations={organizations}
              users={users}
            />
          </div>
        );
      default:
    }

    return [];
  }

  render() {
    const {
      history,
      loading,
      createUser,
      deleteUser,
      updateUser,
      updateUserPassword,
      groups,
      getUserGroups,
      createUserGroup,
      deleteUserGroup,
      refresh,
      impersonate,
      apps,
    } = this.props;

    const { formUserOpen } = this.state;

    return (
      <Page
        helmet="Users"
        loading={loading}
        loadingMessage={"Fetching all Users"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formUserOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
      >
        {this.getStepContent()}
        <FormUser
          open={formUserOpen}
          close={() => {
            this.setState({
              formUserOpen: false,
            });
          }}
          createUser={createUser}
          deleteUser={deleteUser}
          updateUser={updateUser}
          updateUserPassword={updateUserPassword}
          groups={groups}
          getUserGroups={getUserGroups}
          createUserGroup={createUserGroup}
          deleteUserGroup={deleteUserGroup}
          impersonate={impersonate}
          refresh={refresh}
          history={history}
          apps={apps}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperUsersPage);
