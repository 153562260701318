// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableRemove from "components/TableRemove";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class AccessTokensList extends React.Component {
  static propTypes = {
    accessTokens: PropTypes.array,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
  };

  onSelect(selectedAccessToken) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedAccessToken);
    }
  }

  render() {
    const { accessTokens, onDelete } = this.props;

    return (
      <Table
        data={accessTokens}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "userName",
            title: "User Name",
            numeric: false,
          },
          {
            path: "createdAt",
            title: "Creation",
            numeric: false,
          },
          {
            path: "validTo",
            title: "Validity",
            numeric: false,
          },
          {
            path: "id",
            title: "Revoke",
            numeric: false,
            component: TableRemove,
            inject: {
              onRemove: onDelete,
            },
            width: 30,
          },
        ]}
        title={"AccessTokens"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(AccessTokensList);
