// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableServiceName from "components/TableServiceName";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ServiceNamesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    serviceNames: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedServiceName) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedServiceName);
    }
  }

  render() {
    const { serviceNames } = this.props;

    return (
      <Table
        data={serviceNames}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
            component: TableServiceName,
          },
        ]}
        title={"ServiceNames"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ServiceNamesList);
