// material-ui
import { Fab } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import { FormOrganization } from "components";
// custom
import OrganizationsList from "components/OrganizationsList";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperOrganizationPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    organizations: PropTypes.array,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    updateOrganizationUser: PropTypes.func,
    createOrganizationUser: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    getOrganizations: PropTypes.func,
    getOrganizationById: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedOrganization: undefined,
      formOrganizationOpen: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const { index } = this.state;
    const {
      organizations,
      user,
      history,
      deleteOrganizationUser,
      updateOrganization,
      deleteOrganization,
      createOrganizationUser,
      getOrganizationUsers,
      addUserToOrganization,
      updateOrganizationUser,
      getOrganizationById,
      getOrganizations,
    } = this.props;
    const { MessageCenter } = this.context;

    switch (index) {
      case 0:
        return (
          <div
            style={{ maxWidth: 1500, margin: "auto", width: "100%", flex: 1 }}
          >
            <OrganizationsList
              organizations={organizations}
              user={user}
              api={{
                deleteOrganization,
                updateOrganization,
                deleteOrganizationUser,
                getOrganizationUsers,
                addUserToOrganization,
                updateOrganizationUser,
                getOrganizationById,
                getOrganizations,
                createOrganizationUser,
              }}
              getOrganizations={getOrganizations}
              history={history}
              admin
              onSelect={(selectedOrganization) => {
                this.setState({
                  selectedOrganization,
                });
                MessageCenter.open("right");
              }}
            />
          </div>
        );
      default:
    }

    return [];
  }

  render() {
    const {
      history,
      loading,
      deleteOrganization,
      updateOrganization,
      createOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      user,
      addUserToOrganization,
      refresh,
    } = this.props;

    const { MessageCenter } = this.context;

    const { formOrganizationOpen } = this.state;

    return (
      <Page
        helmet="Organizations"
        loading={loading}
        loadingMessage={"Fetching all Organizations"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formOrganizationOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
      >
        {this.getStepContent()}
        <FormOrganization
          open={formOrganizationOpen}
          close={() => {
            this.setState({
              formOrganizationOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedOrganization: undefined,
                }),
              200
            );
          }}
          createOrganization={createOrganization}
          deleteOrganization={deleteOrganization}
          updateOrganization={updateOrganization}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperOrganizationPage);
