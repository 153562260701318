// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// component
import WrapperGroupPage from "../component/WrapperGroupPage";

const mapStateToProps = (state) => ({
  groups: state.groups,
  roles: state.roles,
  apps: state.apps,
  organizations: state.organizations,
});

const mapDispatchToProps = (dispatch) => ({
  getGroups: () => dispatch(Actions.getGroups()),
  getGroupById: (...args) => dispatch(Actions.getGroupById(...args)),
  createGroup: (...args) => dispatch(Actions.createGroup(...args)),
  deleteGroup: (...args) => dispatch(Actions.deleteGroup(...args)),
  updateGroup: (...args) => dispatch(Actions.updateGroup(...args)),

  getRoles: () => dispatch(Actions.getRoles()),
  getApps: () => dispatch(Actions.getApps()),
  getGroupRoles: (...args) => dispatch(Actions.getGroupRoles(...args)),
  getGroupApps: (...args) => dispatch(Actions.getGroupApps(...args)),
  createGroupRole: (...args) => dispatch(Actions.createGroupRole(...args)),
  deleteGroupRole: (...args) => dispatch(Actions.deleteGroupRole(...args)),
  createGroupApp: (...args) => dispatch(Actions.createGroupApp(...args)),
  deleteGroupApp: (...args) => dispatch(Actions.deleteGroupApp(...args)),
  getGroupOrganizations: (...args) =>
    dispatch(Actions.getGroupOrganizations(...args)),
  createGroupOrganization: (...args) =>
    dispatch(Actions.createGroupOrganization(...args)),
  deleteGroupOrganization: (...args) =>
    dispatch(Actions.deleteGroupOrganization(...args)),
  getOrganizations: (...args) => dispatch(Actions.getOrganizations(...args)),
});
class GroupPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    groups: PropTypes.array,
    apps: PropTypes.array,
    getGroups: PropTypes.func,
    getGroupById: PropTypes.func,
    createGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    updateGroup: PropTypes.func,

    getRoles: PropTypes.func,
    getGroupRoles: PropTypes.func,
    createGroupRole: PropTypes.func,
    deleteGroupRole: PropTypes.func,
    getGroupApps: PropTypes.func,
    createGroupApp: PropTypes.func,
    deleteGroupApp: PropTypes.func,
    organizations: PropTypes.array,
    getGroupOrganizations: PropTypes.func,
    createGroupOrganization: PropTypes.func,
    deleteGroupOrganization: PropTypes.func,
    getOrganizations: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, groups } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: groups.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getGroups, getRoles, getApps, getOrganizations } = this.props;
    await Promise.all([getGroups(), getRoles(), getApps(), getOrganizations()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      groups,
      roles,
      apps,
      getGroupById,
      createGroup,
      deleteGroup,
      updateGroup,
      getGroupRoles,
      deleteGroupRole,
      createGroupRole,
      getGroupApps,
      deleteGroupApp,
      createGroupApp,
      organizations,
      getGroupOrganizations,
      createGroupOrganization,
      deleteGroupOrganization,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperGroupPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        groups={groups}
        roles={roles}
        apps={apps}
        history={history}
        getGroupById={getGroupById}
        createGroup={createGroup}
        deleteGroup={deleteGroup}
        updateGroup={updateGroup}
        getGroupRoles={getGroupRoles}
        deleteGroupRole={deleteGroupRole}
        createGroupRole={createGroupRole}
        getGroupApps={getGroupApps}
        deleteGroupApp={deleteGroupApp}
        createGroupApp={createGroupApp}
        organizations={organizations}
        getGroupOrganizations={getGroupOrganizations}
        createGroupOrganization={createGroupOrganization}
        deleteGroupOrganization={deleteGroupOrganization}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);
