import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "unity";
const events = `${REACT_APP_API_PREFIX}/${service}/events`;

export function getEvents(filters) {
  const url = `${events}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getEventById(id) {
  const url = `${events}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEvent(id, body) {
  const url = `${events}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEvent(id) {
  const url = `${events}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEvent(body) {
  const url = `${events}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function testEvent(body) {
  const url = `${events}/testOpa`;
  return {
    method: GET,
    url,
  };
}
