import * as Api from "api";
import {
  CREATE_EVENT,
  DELETE_EVENT,
  GET_EVENTS,
  GET_EVENT_BY_ID,
  TEST_EVENT,
  UPDATE_EVENT,
} from "constants/events";
import { networkAction } from "helpers/network/networkAction";

export const getEvents = () => async (dispatch) =>
  networkAction(dispatch, GET_EVENTS, Api.getEvents, []);

export const getEventById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_BY_ID, Api.getEventById, [id]);

export const deleteEvent = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EVENT, Api.deleteEvent, [id]);

export const createEvent = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT, Api.createEvent, [body]);

export const updateEvent = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EVENT, Api.updateEvent, [id, body]);

export const testEvent = () => async (dispatch) =>
  networkAction(dispatch, TEST_EVENT, Api.testEvent, []);
