import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material-ui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Email from "@material-ui/icons/EmailTwoTone";
import VisibilityOff from "@material-ui/icons/VisibilityOffTwoTone";
import Visibility from "@material-ui/icons/VisibilityTwoTone";
// custom
import { Copyright } from "components";
import {
  PASSWORD_FREE_SIGNIN_ENABLED,
  REACT_APP_LOGO,
  REACT_APP_REDIRECT_AFTER_SIGNIN,
  RESET_PASSWORD_ENABLED,
  SIGN_UP_ENABLED,
} from "config";
import { EMAIL } from "constants/regexp";
import { PROCESSING } from "constants/routes";
import Card from "creativeTim/Card/Card";
import CardHeader from "creativeTim/Card/CardHeader";
import Header from "creativeTim/Header/Header";
// creativeTim components
import HeaderLinks from "creativeTim/Header/HeaderLinks";
import { getJsonFromUrl } from "helpers";
// helpers
import { redirectAccordingToRole } from "helpers/redirect";
import PasswordFreeSignin from "hooks/PasswordFreeSignin";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

const PADDING = 6;

class WrapperSigninPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,

    // Api
    signin: PropTypes.func,
    getCurrentUser: PropTypes.func,
    opaReady: PropTypes.func,
  };

  static contextTypes = {
    openSignup: PropTypes.func,
    openForgotPassword: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      email: urlParams.email || "",
      password: urlParams.password ? window.atob(urlParams.password) : "",
      passwordFreeOpen: false,
      plcode: urlParams.plcode,
      permanent: true,
      loading: false,
    };
  }

  async signin() {
    const { signin, history, getCurrentUser, opaReady } = this.props;
    const { email, password, permanent } = this.state;

    const { localStorage } = window;
    const { redirectUrl } = localStorage;

    this.setState({ loading: true });

    delete localStorage.redirectUrl;

    const resp = await signin({
      email,
      password,
      permanent,
    });

    if (resp.success) {
      this.onAuthenticated(resp);
    } else {
      this.setState({ loading: false });
    }
  }

  async onAuthenticated(resp) {
    const { history, getCurrentUser, opaReady } = this.props;

    const { localStorage } = window;
    const { redirectUrl } = localStorage;
    const check = await opaReady();
    if (!check.success) {
      history.push(
        `${PROCESSING}?redirect=https://${resp.payload.defaultAppURL}`
      );
      return;
    }
    setTimeout(() => getCurrentUser(), 200);
    if (redirectUrl) {
      if (redirectUrl.substring(0, 4) === "http") {
        window.location.replace(`${redirectUrl}`);
      } else {
        history.push(`${redirectUrl}`);
      }
    } else if (REACT_APP_REDIRECT_AFTER_SIGNIN) {
      window.location.replace(REACT_APP_REDIRECT_AFTER_SIGNIN);
    } else {
      if (resp.payload.defaultAppURL) {
        window.location.replace(`https://${resp.payload.defaultAppURL}`);
        return;
      }

      history.push(
        `${redirectAccordingToRole(resp.role)}${window.location.search}`
      );
    }
  }

  render() {
    const { classes, theme } = this.props;
    const { email, password, permanent, showPassword, loading } = this.state;

    const { openSignup, openForgotPassword } = this.context;

    return (
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Helmet>
          <title>Signin</title>
          <meta name="Signin" content="Signin page" />
        </Helmet>
        <Header absolute color="transparent" rightLinks={<HeaderLinks />} />
        <Grid item xs={12}>
          <Zoom in>
            <div className={classes.zoomContainer}>
              <Card className={classes.card}>
                {loading ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{
                      padding: "60px 0px 60px 0px",
                    }}
                  >
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography display="block" variant="body2">
                        Loading ...
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    <form
                      onKeyPress={(e) => {
                        //eslint-disable-line
                        if (e.key === "Enter") {
                          this.signin();
                        }
                      }}
                    >
                      <CardHeader color="primary">
                        <img alt="logo" src={REACT_APP_LOGO} height="60" />
                      </CardHeader>
                      <div
                        style={{
                          overflow: "auto",
                          padding: "15px 30px 15px 30px",
                        }}
                      >
                        <Grid container justify="flex-start">
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="adornment-password">
                                Email
                              </InputLabel>
                              <Input
                                tabindex="1"
                                id="adornment-password"
                                type={"text"}
                                value={email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      tabindex="-1"
                                      edge="end"
                                      aria-label="Toggle password visibility"
                                      // onClick={() => this.setState({ showPassword: !showPassword })}
                                    >
                                      <Email />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: PADDING }}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="adornment-password">
                                Password
                              </InputLabel>
                              <Input
                                tabindex="2"
                                id="adornment-password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      tabindex="-1"
                                      edge="end"
                                      aria-label="Toggle password visibility"
                                      onClick={() =>
                                        this.setState({
                                          showPassword: !showPassword,
                                        })
                                      }
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item style={{ paddingTop: PADDING }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabindex="-1"
                                  size="small"
                                  checked={permanent}
                                  onChange={(e) =>
                                    this.setState({
                                      permanent: e.target.checked,
                                    })
                                  }
                                  value="permanent"
                                />
                              }
                              label={
                                <Typography
                                  display="block"
                                  color="textSecondary"
                                  variant="caption"
                                >
                                  Remember Me
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: PADDING }}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={
                                !password ||
                                !EMAIL.test(email) ||
                                password.length < 8
                              }
                              style={{ textTransform: "none" }}
                              onClick={this.signin.bind(this)}
                            >
                              Login
                            </Button>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: PADDING }}>
                            {RESET_PASSWORD_ENABLED &&
                              !PASSWORD_FREE_SIGNIN_ENABLED && (
                                <div
                                  className={classes.iForgotButton}
                                  onClick={openForgotPassword}
                                >
                                  <Typography
                                    display="block"
                                    variant="caption"
                                    gutterBottom
                                  >
                                    I Forgot my Password
                                  </Typography>
                                </div>
                              )}
                            {PASSWORD_FREE_SIGNIN_ENABLED ? (
                              <div
                                className={classes.iForgotButton}
                                onClick={() =>
                                  this.setState({ passwordFreeOpen: true })
                                }
                              >
                                <Typography
                                  display="block"
                                  variant="caption"
                                  gutterBottom
                                  style={{
                                    color:
                                      EMAIL.test(email) && !password
                                        ? "white"
                                        : theme.palette.text.primary,
                                    background:
                                      EMAIL.test(email) && !password
                                        ? theme.palette.primary.color[700]
                                        : "rgba(0,0,0,0)",
                                    transition: "color .3s, background .3s",
                                    borderRadius: 20,
                                    display: "inline-block",
                                    padding: "0px 8px",
                                  }}
                                >
                                  <i class="fad fa-wand-magic"></i> Send me a
                                  password free sign in code
                                </Typography>
                              </div>
                            ) : (
                              []
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </div>
                )}
              </Card>
              {SIGN_UP_ENABLED && (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.signupButton}
                  onClick={() => openSignup()}
                  style={{ textTransform: "none" }}
                >
                  Create an Account
                </Button>
              )}
            </div>
          </Zoom>
        </Grid>
        <Copyright />
        <PasswordFreeSignin
          open={this.state.passwordFreeOpen}
          onAuthenticated={this.onAuthenticated.bind(this)}
          email={email}
          plcode={this.state.plcode}
          onClose={() => this.setState({ passwordFreeOpen: false })}
        />
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(WrapperSigninPage);
