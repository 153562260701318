import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Portal from "@material-ui/core/Portal";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import DialogRulesList from "components/DialogRulesList";
import FormRole from "components/FormRole";
import Page from "components/Page";
import RoleDetail from "components/RoleDetail";
// components
import RolesList from "components/RolesList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperRolePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    roles: PropTypes.array,
    createRole: PropTypes.func,
    deleteRole: PropTypes.func,
    updateRole: PropTypes.func,
    getRules: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,
    createRule: PropTypes.func,
    serviceNames: PropTypes.array,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedRole: undefined,
      formRoleOpen: false,
      listRulesOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { roles, serviceNames } = this.props;
    const { MessageCenter } = this.context;

    if (index === 0) {
      return (
        <RolesList
          roles={roles}
          selectedRole={this.state.selectedRole}
          onEdit={(selectedRole) => {
            this.setState({
              selectedRole,
            });
            MessageCenter.open("right");
          }}
        />
      );
    }

    return (
      <RolesList
        selectedRole={this.state.selectedRole}
        roles={
          roles &&
          roles.length &&
          serviceNames &&
          serviceNames.length &&
          roles.filter((r) => r.serviceNameID === serviceNames[index - 1].id)
        }
        onEdit={(selectedRole) => {
          this.setState({
            selectedRole,
          });
          MessageCenter.open("right");
        }}
      />
    );
  }

  render() {
    const {
      history,
      loading,
      createRole,
      deleteRole,
      updateRole,
      refresh,

      getRules,
      deleteRule,
      updateRule,
      createRule,

      serviceNames,
    } = this.props;

    const { MessageCenter } = this.context;

    const { selectedRole, formRoleOpen, listRulesOpen } = this.state;

    return (
      <Page helmet="OPA" loading={loading} loadingMessage={"OPA Rules"}>
        {this.getStepContent()}
        <FormRole
          open={formRoleOpen}
          close={() => {
            this.setState({
              formRoleOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedRole: undefined,
                }),
              200
            );
          }}
          createRole={createRole}
          deleteRole={deleteRole}
          updateRole={updateRole}
          serviceNames={serviceNames}
          refresh={refresh}
        />
        <DialogRulesList
          open={listRulesOpen}
          close={() => {
            this.setState({
              listRulesOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedRole: undefined,
                }),
              200
            );
          }}
          role={selectedRole}
          getRules={getRules}
          deleteRule={deleteRule}
          updateRule={updateRule}
          createRule={createRule}
        />
        <Portal container={MessageCenter.getPortalContainer("right")}>
          {selectedRole ? (
            <ClickAwayListener onClickAway={() => console.log("onClickAway")}>
              <RoleDetail
                role={selectedRole}
                deleteRole={deleteRole}
                updateRole={updateRole}
                serviceNames={serviceNames}
                getRules={getRules}
                deleteRule={deleteRule}
                updateRule={updateRule}
                createRule={createRule}
                refresh={refresh}
                history={history}
                close={() => {
                  this.setState({ selectedRole: undefined });
                  MessageCenter.close("right");
                }}
                refreshKey={selectedRole ? selectedRole.id : undefined}
              />
            </ClickAwayListener>
          ) : (
            []
          )}
        </Portal>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperRolePage);
