import { InputAdornment, TextField } from "@material-ui/core";
import {
  AsYouType,
  formatIncompletePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import React from "react";
// styles

export default function PhoneInput(props) {
  const [phoneCountry, setPhoneCountry] = React.useState("US");
  const [phone, setPhone] = React.useState("");

  React.useEffect(() => {
    console.log(props.value, "CHANGED!");
    initPhoneNumber(props.value ?? "");
  }, [props.value]);

  function initPhoneNumber(e) {
    if (e) {
      try {
        const phoneNumber = parsePhoneNumber(e);
        console.log(e, phoneNumber);
        if (phoneNumber) {
          setPhoneCountry(phoneNumber.country);
          setPhone(new AsYouType(phoneNumber.country).input(e));
        }
      } catch (error) {
        console.log(error);
        setPhone(formatIncompletePhoneNumber(e));
        setPhoneCountry("US");
      }
    } else {
      setPhone("");
    }
  }

  function handlePhoneChange(e) {
    const value = e.target.value;
    const asYouType = new AsYouType(phoneCountry).input(value);
    setPhone(asYouType);
    if (value) {
      try {
        const phoneNumber = parsePhoneNumber(value, phoneCountry);
        if (phoneNumber) {
          setPhoneCountry(phoneNumber.country ?? "US");
          if (isValidPhoneNumber(value, phoneNumber.country)) {
            props.onChange({ target: { value: phoneNumber.format("E.164") } });
          }
        }
      } catch (error) {
        setPhoneCountry("US");
      }
    }
  }

  const InputProps = { ...props.InputProps };
  InputProps.startAdornment = (
    <InputAdornment
      style={{
        paddingRight: 10,
      }}
      position="start"
    >
      {phoneCountry}
    </InputAdornment>
  );

  return (
    <TextField
      {...props}
      onChange={handlePhoneChange}
      value={phone}
      InputProps={InputProps}
    />
  );
}
