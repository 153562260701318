import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AlternateEmail from "@material-ui/icons/AlternateEmailTwoTone";
import People from "@material-ui/icons/People";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TemplateDetail extends Component {
  static propTypes = {
    classes: PropTypes.object,
    close: PropTypes.func,
    template: PropTypes.object,
    deleteTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { template } = this.props;
    this.state = {
      ...template,
      index: 0,
      loading: false,
      method: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.init(nextProps.template);
    }
  }

  init(template) {
    //eslint-disable-line
    this.setState({
      ...template,
      loading: false,
    });
  }

  getStepContent(index) {
    const { classes } = this.props;

    const {
      title,
      button,
      code,
      loading,
      description,
      deleteValidation,
    } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <TextField
                id="title"
                fullWidth
                label="Title"
                className={classes.textField}
                value={title}
                onChange={this.handleChange("title")}
                onBlur={() => this.save("title")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("title");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="code"
                fullWidth
                label="Code"
                className={classes.textField}
                value={code}
                onChange={this.handleChange("code")}
                onBlur={() => this.save("code")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("code");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="button"
                fullWidth
                label="Button"
                className={classes.textField}
                value={button}
                onChange={this.handleChange("button")}
                onBlur={() => this.save("button")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("button");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                fullWidth
                label="Description"
                multiline
                className={classes.textField}
                value={description}
                onChange={this.handleChange("description")}
                onBlur={() => this.save("description")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("description");
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Delete
              </Typography>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Enter the service's code to delete
                </Typography>
                <TextField
                  id="email"
                  variant="filled"
                  fullWidth
                  label={`${code}`}
                  className={classes.textField}
                  value={deleteValidation}
                  onChange={this.handleChange("deleteValidation")}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  style={{
                    opacity: deleteValidation !== code ? 0.5 : 1,
                  }}
                  size="small"
                  disabled={deleteValidation !== code}
                  onClick={this.deleteTemplate.bind(this)}
                >
                  Delete Template
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container>
            <Grid item>Unkown Step</Grid>
          </Grid>
        );
    }
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateTemplate, refresh, template } = this.props;

    await updateTemplate(template.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async deleteTemplate() {
    const { template, deleteTemplate, close, refresh } = this.props;
    await deleteTemplate(template.id);
    refresh();
    close();
  }

  render() {
    const { classes } = this.props;
    const { index, name, id } = this.state;

    return (
      <Grid container className={classes.container} direction="column">
        <Grid
          item
          style={{
            height: "calc(100vh - 57px)",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
          }}
        >
          <div
            style={{
              padding: 16,
              borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <AlternateEmail fontSize="large" />
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  {name}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  #{id}
                </Typography>
              </Grid>
            </Grid>
          </div>
          {this.getStepContent(index)}
        </Grid>
        <Grid item>
          <BottomNavigation
            value={index}
            onChange={(event, newIndex) => {
              this.setState({ index: newIndex });
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction label="General" icon={<People />} />
            <BottomNavigationAction label="Danger" icon={<Warning />} />
          </BottomNavigation>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TemplateDetail);
