// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableMethod from "components/TableMethod";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class RulesList extends React.Component {
  static propTypes = {
    rules: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedRule) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedRule);
    }
  }

  render() {
    const { rules } = this.props;

    return (
      <Table
        data={rules}
        meta={[
          {
            path: "method",
            title: "Method",
            numeric: false,
            component: TableMethod,
          },
          {
            path: "path",
            title: "Path",
            numeric: false,
          },
          {
            path: "roleName",
            title: "Role",
            numeric: false,
          },
        ]}
        title={"Rules"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(RulesList);
