import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "account";
const groups = `${REACT_APP_API_PREFIX}/${service}/groups`;
const groupRoles = `${REACT_APP_API_PREFIX}/${service}/groupRoles`;
const groupApps = `${REACT_APP_API_PREFIX}/${service}/groupApps`;
const groupOrganizations = `${REACT_APP_API_PREFIX}/${service}/organizationGroups`;

export function getGroups() {
  const url = `${groups}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getGroupById(id) {
  const url = `${groups}/${id}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getGroupRoles(id) {
  const url = `${groups}/${id}/roles`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getGroupOrganizations(id) {
  const url = `${groups}/${id}/organizations`;
  return {
    service,
    method: GET,
    url,
  };
}

export function deleteGroupOrganization(groupOrganizationID) {
  const url = `${groupOrganizations}/${groupOrganizationID}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGroupOrganization(body) {
  const url = `${groupOrganizations}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getGroupApps(id) {
  const url = `${groups}/${id}/apps`;
  return {
    service,
    method: GET,
    url,
  };
}

export function deleteGroupApp(groupAppID) {
  const url = `${groupApps}/${groupAppID}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGroupApp(body) {
  const url = `${groupApps}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function createGroupRole(id, body) {
  const url = `${groups}/${id}/roles`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function deleteGroupRole(id, roleID) {
  const url = `${groupRoles}/${roleID}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function updateGroup(id, body) {
  const url = `${groups}/${id}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function deleteGroup(id) {
  const url = `${groups}/${id}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function createGroup(body) {
  const url = `${groups}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
