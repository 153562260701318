import { SIGNIN } from "constants/sessions";
import { GET_CURRENT_USER, SIGNIN_PL } from "constants/users";

export default (state = {}, action) => {
  let user;
  if (action.payload) {
    user = action.payload;
  }

  switch (action.type) {
    case `${GET_CURRENT_USER}_SUCCESS`:
    case `${SIGNIN}_SUCCESS`:
    case `${SIGNIN_PL}_SUCCESS`:
      return user;
    default:
      return state;
  }
};
