import { serviceRoles } from "config";
import { EVENTS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import EventsPage from "./container/EventsPage";

export default function getRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    path: EVENTS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: EventsPage,
  };
}
