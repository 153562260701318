import events from "./events";
import templates from "./templates";
import settings from "./settings";
import organizations from "./organizations";
import apps from "./apps";
import serviceNames from "./serviceNames";
import accessTokens from "./accessTokens";
import rules from "./rules";
import roles from "./roles";
import groups from "./groups";
import users from "./users";
import errors from "./errors";
import app from "./app";
import user from "./user";
import userApps from "./userApps";
import searchPresets from "./searchPresets";
import { combineReducers } from "redux"; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  userApps,
  app,
  users,
  groups,
  roles,
  rules,
  serviceNames,
  accessTokens,
  apps,
  organizations,
  settings,
  searchPresets,
  templates,
  events,
});
