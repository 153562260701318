// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableServiceName extends Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <span
        style={{
          background: datum.color,
          color: "white",
          borderRadius: 12,
          padding: "2px 8px 2px 8px",
        }}
      >
        {datum.name}
      </span>
    );
  }
}

export default withStyles(styles)(TableServiceName);
