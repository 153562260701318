import Avatar from "@material-ui/core/Avatar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Accessibility from "@material-ui/icons/Accessibility";
import Group from "@material-ui/icons/Group";
import Person from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
// reactor
import App from "components/App";
// regexp
import {
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_8_CHAR,
  EMAIL,
} from "constants/regexp";
// creative-tim
import Badge from "creativeTim/Badge/Badge";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class UserDetail extends Component {
  static propTypes = {
    classes: PropTypes.object,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,
    deleteUser: PropTypes.func,
    user: PropTypes.object,
    groups: PropTypes.array,
    apps: PropTypes.array,
    getUserGroups: PropTypes.func,
    history: PropTypes.func,
    createUserGroup: PropTypes.func,
    deleteUserGroup: PropTypes.func,
    impersonate: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { user } = this.props;
    this.state = {
      ...user,
      index: 0,
      showPassword: false,
      password: "",
      passwordConfirmation: "",
      emailBlur: false,
      eightChar: false,
      numerical: false,
      alphabetical: false,
      validEmail: false,
      match: false,
      showPasswordRetype: false,
      userGroups: [],
    };
    this.refresh(user);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.init(nextProps.user);
    }
  }

  init(user) {
    //eslint-disable-line
    this.setState({
      ...user,
      showPassword: false,
      password: "",
      passwordConfirmation: "",
      emailBlur: false,
      eightChar: false,
      numerical: false,
      alphabetical: false,
      validEmail: false,
      match: false,
      showPasswordRetype: false,
      userGroups: [],
    });
    this.refresh(user);
  }

  async deleteGroup(group) {
    const { deleteUserGroup, user } = this.props;
    await deleteUserGroup(user.id, group.id);
    this.refresh(user);
  }

  async addGroup(group) {
    const { createUserGroup, user } = this.props;
    await createUserGroup(user.id, { groupID: group.id });
    this.refresh(user);
  }

  getStepContent(index) {
    const { classes, user, groups, apps } = this.props;

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      eightChar,
      numerical,
      alphabetical,
      match,
      active,
      focused,
      showPassword,
      showPasswordRetype,
      validEmail,
      emailBlur,
      userGroups,
      groupLoading,
      loading,
      defaultAppID,
      fullName,
    } = this.state;

    const groupUserCanAdd = groups.filter(
      (g) => userGroups.find((ug) => ug.groupID === g.id) === undefined
    );

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <TextField
                id="firstName"
                fullWidth
                label="First Name *"
                className={classes.textField}
                value={firstName}
                onChange={this.handleChange("firstName")}
                onBlur={() => this.save("firstName")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("firstName");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="lastName"
                fullWidth
                label="Last Name *"
                className={classes.textField}
                value={lastName}
                onChange={this.handleChange("lastName")}
                onBlur={() => this.save("lastName")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("lastName");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!validEmail && emailBlur}
                id="email"
                fullWidth
                label="Email *"
                className={classes.textField}
                value={email}
                onChange={this.handleChange("email")}
                onBlur={() => this.save("email")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("email");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="default-app">Default App</InputLabel>
                <Select
                  inputProps={{
                    name: "Default App<",
                    id: "default-app",
                  }}
                  value={defaultAppID}
                  onChange={(e) => {
                    this.state.defaultAppID = e.target.value; //eslint-disable-line
                    this.save("defaultAppID");
                  }}
                >
                  <MenuItem value={0}>
                    <Chip
                      className={classes.chip}
                      style={{
                        color: "white",
                        background: "#9e9e9e",
                      }}
                      label={"None"}
                    />
                  </MenuItem>
                  {apps.map((a) => (
                    <MenuItem key={`app_${a.id}`} value={a.id}>
                      <Chip
                        avatar={
                          <Avatar style={{ background: "#424242" }}>
                            <App {...a} size={20} />
                          </Avatar>
                        }
                        key={`chipApp_${a.id}`}
                        className={classes.chip}
                        style={{
                          color: "white",
                          background: "#424242",
                        }}
                        label={`${a.name}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              {user !== undefined && (
                <Grid item xs={12}>
                  {groupUserCanAdd.length && (
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="group-simple">
                        Add Groups to User
                      </InputLabel>
                      <Select
                        inputProps={{
                          name: "Add Groups to User",
                          id: "group-simple",
                        }}
                        onChange={(e) => this.addGroup(e.target.value)}
                      >
                        {groupUserCanAdd.map((g) => (
                          <MenuItem key={`role_${g.id}`} value={g}>
                            {g.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <br />
                  <br />
                  {groupLoading ? (
                    <CircularProgress size={30} />
                  ) : (
                    <div>
                      {userGroups &&
                        userGroups.map((g) => (
                          <Chip
                            key={`chip_${g.id}`}
                            className={classes.chip}
                            label={g.groupName}
                            onDelete={() => this.deleteGroup(g)}
                          />
                        ))}
                      {userGroups.length === 0 && (
                        <Typography display="block" variant="caption">
                          No Groups
                        </Typography>
                      )}
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Change Password
              </Typography>
              <FormControl fullWidth className={classes.textField}>
                <InputLabel htmlFor="adornment-password">Password</InputLabel>
                <Input
                  id="adornment-password"
                  onFocus={() => this.setState({ focused: true })}
                  label="Password *"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={this.handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          this.setState({ showPassword: !showPassword })
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.textField}>
                <InputLabel htmlFor="adornment-password-retype">
                  Password Retype
                </InputLabel>
                <Input
                  onFocus={() => this.setState({ focused: true })}
                  type={showPasswordRetype ? "text" : "password"}
                  id="passwordConfirmation"
                  label="Retype Password *"
                  value={passwordConfirmation}
                  onChange={this.handleChange("passwordConfirmation")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          this.setState({
                            showPasswordRetype: !showPasswordRetype,
                          })
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPasswordRetype ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {focused && (
              <Grid item xs={12}>
                <Badge color={eightChar ? "success" : "warning"}>
                  At least 8 characters
                </Badge>
                <Badge
                  color={numerical ? "success" : "warning"}
                  className={classes.badge}
                >
                  At least 1 digit
                </Badge>
                <Badge
                  color={alphabetical ? "success" : "warning"}
                  className={classes.badge}
                >
                  At least 1 letter
                </Badge>
                <Badge
                  color={match ? "success" : "warning"}
                  className={classes.badge}
                >
                  Passwords match
                </Badge>
              </Grid>
            )}
            {focused ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  opacity: !(eightChar && numerical && alphabetical && match)
                    ? 0.5
                    : 1,
                }}
                disabled={!(eightChar && numerical && alphabetical && match)}
                onClick={this.updateUserPassword.bind(this)}
              >
                Change Password
              </Button>
            ) : (
              []
            )}
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Status
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={() => {
                      this.setState((prevState) => ({
                        active: !prevState.active,
                      })).then(() => {
                        this.save("active");
                      });
                    }}
                    value="active"
                  />
                }
                label="Email Verified (Active)"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Delete
              </Typography>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Enter the user's fullname to delete.
                </Typography>
                <TextField
                  error={!validEmail && emailBlur}
                  id="email"
                  variant="filled"
                  fullWidth
                  label={`${firstName} ${lastName}`}
                  className={classes.textField}
                  value={fullName}
                  onChange={this.handleChange("fullName")}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  style={{
                    opacity: fullName !== `${firstName} ${lastName}` ? 0.5 : 1,
                  }}
                  size="small"
                  disabled={fullName !== `${firstName} ${lastName}`}
                  onClick={this.deleteUser.bind(this)}
                >
                  Delete User
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container>
            <Grid item>Unkown Step</Grid>
          </Grid>
        );
    }
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    const { passwordConfirmation, password } = this.state;

    if (name === "email") {
      let validEmail = false;
      if (EMAIL.test(value)) {
        validEmail = true;
      }

      this.setState({ validEmail });
    }

    if (name === "password") {
      let eightChar = false;
      let numerical = false;
      let alphabetical = false;
      let match = false;

      if (AT_LEAST_8_CHAR.test(value)) {
        eightChar = true;
      }

      if (AT_LEAST_1_NUMERICAL_CHAR.test(value)) {
        numerical = true;
      }

      if (AT_LEAST_1_ALPHABETICAL_CHAR.test(value)) {
        alphabetical = true;
      }

      if (value === passwordConfirmation) {
        match = true;
      }

      this.setState({
        eightChar,
        numerical,
        alphabetical,
        match,
      });
    }

    if (name === "passwordConfirmation") {
      let match = false;
      if (value === password) {
        match = true;
      }

      this.setState({ match });
    }

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateUser, refresh, user } = this.props;

    await updateUser(user.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async refresh(user) {
    const { getUserGroups } = this.props;
    this.setState({ groupLoading: true });
    const resp = await getUserGroups(user.id);
    this.setState({ groupLoading: false });
    if (resp.success) {
      this.setState({
        userGroups: resp.payload,
      });
    }
  }

  async deleteUser() {
    const { user, deleteUser, close, refresh } = this.props;
    await deleteUser(user.id);
    refresh();
    close();
  }

  async updateUserPassword() {
    const { updateUserPassword, user } = this.props;
    const { NotificationCenter } = this.context;
    const { password, passwordConfirmation } = this.state;

    this.setState({ loading: true });
    const resp = await updateUserPassword(user.id, {
      password,
      passwordConfirmation,
    });
    if (resp.success) {
      this.setState({
        loading: false,
        password: "",
        passwordConfirmation: "",
        focused: false,
      });
      NotificationCenter.sweetAlert(
        {
          title: "Success !",
          subtitle: "The new password has been set.",
          success: true,
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { classes, history, impersonate, user } = this.props;
    const { index, firstName, lastName, uuid, createdAt } = this.state;

    return (
      <Grid container className={classes.container} direction="column">
        <Grid
          item
          style={{
            height: "calc(100vh - 57px)",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
          }}
        >
          <div
            style={{
              padding: 16,
              borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Avatar>
                  {firstName && lastName
                    ? `${firstName[0]}${lastName[0]}`
                    : "U"}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  {firstName} {lastName}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {uuid}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Created on {moment(createdAt).calendar()}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await impersonate(user);
                    history.push("/");
                  }}
                >
                  <Person /> Impersonate
                </Button>
              </Grid>
            </Grid>
          </div>
          {this.getStepContent(index)}
        </Grid>
        <Grid item>
          <BottomNavigation
            value={index}
            onChange={(event, newIndex) => {
              this.setState({ index: newIndex });
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction label="General" icon={<Accessibility />} />
            <BottomNavigationAction label="Groups" icon={<Group />} />
            <BottomNavigationAction label="Danger" icon={<Warning />} />
          </BottomNavigation>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(UserDetail);
