import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperProcessingPage from "../component/WrapperProcessingPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  opaReady: (...args) => dispatch(Actions.opaReady(...args)),
});
class ProcessingPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    opaReady: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  render() {
    const { location, history, opaReady } = this.props;

    const { urlParams } = this.state;

    return (
      <WrapperProcessingPage
        history={history}
        location={location}
        urlParams={urlParams}
        opaReady={opaReady}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcessingPage);
