export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const USERS = "/users";
export const GROUP = "/groups";
export const ROLE = "/roles";
export const PATH = "/paths";
export const APPS = "/apps";
export const SERVICE_NAME = "/serviceNames";
export const APPLICATIONS = "/applications";
export const PROCESSING = "/processing";
export const SETTINGS = "/settings";
export const TEMPLATES = "/templates";
export const ORGANIZATIONS = "/organizations";
export const OPA = "/opa";
export const EVENTS = "/events";
export const JOIN_ORGANIZATION = "/joinOrganization";
