export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUP_BY_ID = "GET_GROUP_BY_ID";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const GET_GROUP_ROLES = "GET_GROUP_ROLES";
export const GET_GROUP_APPS = "GET_GROUP_APPS";
export const DELETE_GROUP_ROLE = "DELETE_GROUP_ROLE";
export const CREATE_GROUP_ROLE = "CREATE_GROUP_ROLE";
export const DELETE_GROUP_APP = "DELETE_GROUP_APP";
export const CREATE_GROUP_APP = "CREATE_GROUP_APP";
export const GET_GROUP_ORGANIZATIONS = "GET_GROUP_ORGANIZATIONS";
export const DELETE_GROUP_ORGANIZATION = "DELETE_GROUP_ORGANIZATION";
export const CREATE_GROUP_ORGANIZATION = "CREATE_GROUP_ORGANIZATION";
