import { GET_TEMPLATES } from "constants/templates";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_TEMPLATES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
