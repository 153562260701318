// import PropTypes from 'prop-types';
// material-ui
import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreVert from "@material-ui/icons/MoreVert";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Search from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import ChoiceModal from "components/ChoiceModal";
import { USERS } from "constants/routes";
import { getJsonFromUrl } from "helpers";
import CopyLabel from "hooks/CopyLabel";
import OrganizationInvite from "hooks/OrganizationInvite";
import React from "react";
import { FixedSizeList } from "react-window";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const RenderRow = ({
  index,
  style,
  users,
  filteredList,
  search,
  history,
  api,
  refresh,
  NotificationCenter,
  reinvite,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [choiceOpen, setChoiceOpen] = React.useState(false);
  let item = users[index];
  if (search) {
    item = filteredList[index];
  }

  const removeFromOrganization = () => {
    api.deleteOrganizationUser(item.relationID).then((r) => {
      if (r.success) {
        NotificationCenter.sweetAlert({
          title: `${item.firstName} ${item.lastName} has been removed.`,
          success: true,
          timestamp: new Date().getTime(),
        });
        refresh();
        setTimeout(NotificationCenter.hide, 1500);
      }
    });
  };

  const requestDelete = () => {
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: `You are about to remove ${item.firstName} ${item.lastName} from the organization.`,
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: removeFromOrganization,
        },
      }
    );
  };

  return (
    <>
      <ListItem style={style} key={item.id} divider>
        <ListItemText
          primary={
            <div style={{ display: "flex", alignItems: "center" }}>
              {item.firstName || item.lastName ? (
                <Typography style={{ marginRight: 4 }}>
                  {item.firstName} {item.lastName}
                </Typography>
              ) : (
                []
              )}
              {item.pending ? (
                <Typography
                  variant="overline"
                  style={{
                    border: "solid 1px #ff9800",
                    padding: "2px 4px",
                    color: "#ff9800",
                    lineHeight: 1,
                  }}
                >
                  Pending
                </Typography>
              ) : (
                []
              )}
              {item.id === 0 ? (
                <Typography
                  variant="overline"
                  style={{
                    border: "solid 1px #f44336",
                    padding: "2px 4px",
                    color: "#f44336",
                    lineHeight: 1,
                    marginLeft: 4,
                  }}
                >
                  No Account
                </Typography>
              ) : (
                []
              )}
            </div>
          }
          secondary={item.email}
        />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disabled={item.id === 0}
          onClick={() => {
            window.open(`${USERS}?userID=${item.id}`, "_blank");
          }}
        >
          <ListItemText primary="Show User" />
          <ListItemSecondaryAction>
            <OpenInNewIcon />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem
          disabled={item.id && !item.pending}
          onClick={() => {
            setAnchorEl(null);
            setChoiceOpen(true);
          }}
        >
          <ListItemText primary="Invite Again" />
          <ListItemSecondaryAction>
            <SendIcon />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem onClick={requestDelete}>
          <ListItemText primary="Remove from Organization" />
          <ListItemSecondaryAction>
            <RemoveCircleOutlineIcon />
          </ListItemSecondaryAction>
        </MenuItem>
      </Menu>
      <ChoiceModal
        open={choiceOpen}
        onClose={() => setChoiceOpen(false)}
        title={"What would like to do?"}
        options={[
          {
            icon: <SendIcon />,
            color: "#2196f3",
            title: "Resend Invite",
            subtitle: "Let the user join by himself",
            callback: () => {
              reinvite(item, false);
              setChoiceOpen(false);
            },
          },
          {
            icon: <ControlPointIcon />,
            color: "#9e9e9e",
            title: "Force Join",
            subtitle: "Add the user to the org instantly",
            callback: () => {
              reinvite(item, true);
              setChoiceOpen(false);
            },
          },
        ]}
      />
    </>
  );
};

export default function OrganizationDetail({
  api,
  UploadCenter,
  organization,
  NotificationCenter,
  history,
  loading,
}) {
  const [name, setName] = React.useState(organization.name);
  const [organizationType, setOrganizationType] = React.useState(
    organization.organizationType
  );
  const [referenceID, setReferenceID] = React.useState(
    organization.referenceID
  );
  const [active, setActive] = React.useState(organization.active);
  const [logo, setLogo] = React.useState(organization.logo);
  const [search, setSearch] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [userInit, setUserInit] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [usersLoading, setUsersLoading] = React.useState(true);
  const [organizationInviteOpen, setOrganizationInviteOpen] = React.useState(
    false
  );

  const upload = async () => {
    const firstFile = files[0];
    UploadCenter.upload(firstFile).then((r) => {
      setLogo(r.payload);
      save("logo", r.payload, true);
    });
  };

  const userDidDrop = (f) => {
    setFiles(f);
  };

  React.useEffect(() => {
    setName(organization.name);
    setLogo(organization.logo);
    setOrganizationType(organization.organizationType);
    setReferenceID(organization.referenceID);
    if (!userInit) {
      setUsers([]);
      UploadCenter.register(
        upload,
        userDidDrop,
        () => console.log("upload callback"),
        undefined
      );
      setUserInit(true);
      setUsersLoading(true);
      api.getOrganizationUsers(organization.id).then((r) => {
        setUsersLoading(false);
        setUsers(r.payload);
      });
    }
  }, [organization, loading, api]);

  const classes = useStyles(styles);

  const save = (name, value, needRefresh) => async () => {
    await api.updateOrganization(organization.id, {
      [name]: value,
    });

    if (needRefresh) {
      const urlParams = getJsonFromUrl(window.location);
      api.getOrganizations(urlParams.filters);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        style={{ textAlign: "left", minHeight: 540 }}
        justify="space-between"
        spacing={2}
      >
        <Grid item>
          <Skeleton style={{ width: 200, height: 30 }} variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 100 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 220 }} />
            <Skeleton variant="text" style={{ width: 198 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 202 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
        </Grid>
        <Grid xs={12} style={{ marginBottom: 8 }}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <Skeleton variant="rect" style={{ borderRadius: 80, width: 80 }} />
          <Skeleton
            variant="rect"
            style={{ borderRadius: 80, width: 120, marginLeft: 80 }}
          />
        </Grid>
      </Grid>
    );
  }

  const deleteOrganization = () => {
    api.deleteOrganization(organization.id).then((r) => {
      if (r.success) {
        NotificationCenter.sweetAlert({
          title: `${organization.name} has been deleted.`,
          success: true,
          timestamp: new Date().getTime(),
        });
        const urlParams = getJsonFromUrl(window.location);
        api.getOrganizations(urlParams.filters);
        setTimeout(NotificationCenter.hide, 1500);
      }
    });
  };

  const reinvite = (organizationUser, instantJoin) => {
    api.updateOrganizationUser(organizationUser.relationID, {
      instantJoin,
    });
    NotificationCenter.sweetAlert({
      title: "Invitation Sent!",
      success: true,
      timestamp: new Date().getTime(),
    });
    setTimeout(NotificationCenter.hide, 2000);
    api.getOrganizationUsers(organization.id).then((r) => {
      setUsersLoading(false);
      setUsers(r.payload);
    });
  };

  const requestDelete = () => {
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: `You are about to delete ${organization.name}. You will loose all its content.`,
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: deleteOrganization,
        },
      }
    );
  };

  return (
    <Grid
      container
      style={{ textAlign: "left" }}
      justify="space-between"
      spacing={2}
    >
      {logo ? (
        <Grid item xs={12}>
          <img style={{ height: 80 }} src={logo} alt="logo" />
        </Grid>
      ) : (
        []
      )}
      <Grid item>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"Owner: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel
              label={`${organization.ownerFullName} (${organization.ownerEmail})`}
            />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"Organization Token: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel label={organization.token} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{"ID: "}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            <CopyLabel label={organization.id} />
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} style={{ marginBottom: 8 }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={save("name", name, true)}
          label="Name"
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={organizationType}
          onChange={(e) => setOrganizationType(e.target.value)}
          onBlur={save("organizationType", organizationType, false)}
          label="Organization Type"
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={referenceID}
          onChange={(e) => setReferenceID(e.target.value)}
          onBlur={save("referenceID", Number(organizationType), false)}
          label="Reference ID"
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          onBlur={save("logo", logo, false)}
          label="Logo URL"
          style={{ background: "rgba(155,155,155,0.1)" }}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid xs={12} style={{ marginBottom: 8 }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">
            {users.length || "-"} Users
          </Typography>
          <Chip
            label="Invite People"
            onClick={() => setOrganizationInviteOpen(true)}
            icon={<SendIcon style={{ color: "white" }} />}
            style={{ background: "#607d8b", color: "white" }}
          />
          <OrganizationInvite
            organization={organization}
            api={api}
            refresh={() => {
              api.getOrganizationUsers(organization.id).then((r) => {
                setUsersLoading(false);
                setUsers(r.payload);
              });
            }}
            NotificationCenter={NotificationCenter}
            open={organizationInviteOpen}
            close={() => setOrganizationInviteOpen(false)}
          />
        </div>
        {usersLoading ? (
          <CircularProgress size={30} />
        ) : (
          <List
            dense
            className={classes.root}
            style={{
              maxHeight: 600,
              overflow: "scroll",
              background: "rgba(155,155,155,0.1)",
              border: "solid 1px rgba(155,155,155,0.3)",
              borderRadius: 4,
              marginTop: 8,
              padding: 0,
            }}
          >
            <div
              style={{
                position: "sticky",
                top: 0,
                padding: 8,
                background: "rgba(155,155,155,0.1)",
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(8px)",
                zIndex: 99,
              }}
            >
              <InputBase
                style={{
                  background: "#ffffff",
                  width: "calc(100% - 16px)",
                  border: "solid 1px rgba(155,155,155,0.3)",
                  borderRadius: 40,
                  padding: "0px 8px",
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setFilteredList(
                    (users &&
                      users.filter(
                        (a) =>
                          `${a.firstName} ${a.lastName} ${a.email}`
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1
                      )) ||
                      []
                  );
                }}
                fullWidth
                autoFocus
                startAdornment={
                  <Search style={{ color: "rgba(155,155,155,0.8)" }} />
                }
                placeholder="Search..."
              />
            </div>
            <FixedSizeList
              height={600}
              itemSize={61}
              itemCount={search ? filteredList.length : users.length}
            >
              {(a) => {
                return (
                  <RenderRow
                    style={a.style}
                    reinvite={reinvite}
                    index={a.index}
                    key={a.index}
                    refresh={() => {
                      api.getOrganizationUsers(organization.id).then((r) => {
                        setUsersLoading(false);
                        setUsers(r.payload);
                      });
                    }}
                    NotificationCenter={NotificationCenter}
                    api={api}
                    history={history}
                    users={users}
                    filteredList={filteredList}
                    search={search}
                  />
                );
              }}
            </FixedSizeList>
          </List>
        )}
      </Grid>
      <Grid item>
        <Chip
          label="Delete Organization"
          onClick={requestDelete}
          icon={<DeleteForeverIcon />}
          style={{ marginLeft: 8 }}
        />
      </Grid>
    </Grid>
  );
}
