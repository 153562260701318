import { GET_APPS } from "constants/apps";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_APPS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
