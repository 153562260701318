import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Zoom from "@material-ui/core/Zoom";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Confetti from "react-confetti";
// styles
import styles from "./styles";

class ChoiceModal extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    open: PropTypes.bool,
    close: PropTypes.func,
    title: PropTypes.string,
    options: PropTypes.array,
    confetti: PropTypes.bool,
  };

  render() {
    const {
      fullScreen,
      open,
      classes,
      close,
      title,
      confetti,
      options,
    } = this.props;

    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          scroll="paper"
          maxWidth="sm"
          fullWidth
          onClose={close}
          TransitionComponent={Zoom}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <div style={{ paddingBottom: 8 }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                style={{
                  textAlign: "center",
                }}
                spacing={2}
              >
                {options.map((o) => (
                  <Grid
                    item
                    xs={6}
                    onClick={o.callback}
                    key={o.title}
                    style={{ height: "100%" }}
                  >
                    <div
                      style={{
                        background: o.color,
                        borderRadius: 4,
                        color: "white",
                        padding: 16,
                        cursor: "pointer",
                        height: "100%",
                      }}
                    >
                      <Avatar
                        style={{
                          margin: "auto",
                          background: "rgba(255,255,255,0.3)",
                        }}
                      >
                        {o.icon}
                      </Avatar>
                      <Typography variant="h6">{o.title}</Typography>
                      <Typography variant="caption">{o.subtitle}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {confetti && open ? (
          <div style={{ zIndex: 1301, position: "fixed", top: 0, left: 0 }}>
            <Confetti
              recycle={false}
              gravity={0.2}
              tweenDuration={4000}
              numberOfPieces={200}
            />
          </div>
        ) : (
          []
        )}
      </>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(ChoiceModal));
