// actions
import * as Actions from "actions";
import { removeCookie } from "helpers/cookie";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// component
import WrapperSigninPage from "../component/WrapperSigninPage";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  signin: (...args) => dispatch(Actions.signin(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  opaReady: (...args) => dispatch(Actions.opaReady(...args)),
});
class SigninPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // api
    signin: PropTypes.func,
    getCurrentUser: PropTypes.func,
    opaReady: PropTypes.func,

    // reducer
    session: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    removeCookie("auth-impersonate-token");
    removeCookie("auth-server-token");
  }

  render() {
    const {
      history,
      location,
      signin,
      session,
      getCurrentUser,
      opaReady,
    } = this.props;
    return (
      <WrapperSigninPage
        getCurrentUser={getCurrentUser}
        history={history}
        location={location}
        signin={signin}
        session={session}
        opaReady={opaReady}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
