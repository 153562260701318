export const routeNames = [
  "Default",
  "MyAccount",
  "Signin",
  "Users",
  "Group",
  "Role",
  "Apps",
  "ServiceName",
  "Applications",
  "Processing",
  "Settings",
  "Templates",
  "Organizations",
  "Opa",
  "Events",
  "JoinOrganization",
];
