// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperJoinOrganizationPage from "../component/WrapperJoinOrganizationPage";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  joinOrganization: (...args) => dispatch(Actions.joinOrganization(...args)),
  getOrganizationWithToken: (...args) =>
    dispatch(Actions.getOrganizationWithToken(...args)),
});
class JoinOrganizationPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    joinOrganization: PropTypes.func,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      token: urlParams.token,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getOrganizationWithToken } = this.props;
    const { token } = this.state;
    const r = await getOrganizationWithToken(token);
    if (r.success) {
      this.setState({
        loading: false,
        organization: r.payload,
      });
    }
  }

  render() {
    const { location, history, user, joinOrganization } = this.props;

    const { urlParams, loading, token, organization } = this.state;

    return (
      <WrapperJoinOrganizationPage
        history={history}
        token={token}
        organization={organization}
        joinOrganization={joinOrganization}
        location={location}
        urlParams={urlParams}
        user={user}
        refresh={this.refresh.bind(this)}
        loading={loading}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinOrganizationPage);
