import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// custom
import Email from "components/Email";

// reactor
import Page from "components/Page";

// styles
import styles from "./styles";

class WrapperSettingPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    settings: PropTypes.array,
    templates: PropTypes.array,
    updateSetting: PropTypes.func,
    sendTemplateEmail: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const {
      classes,
      history,
      loading,
      settings,
      updateSetting,
      refresh,
      templates,
      sendTemplateEmail,
    } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Settings"
        loading={loading}
        loadingMessage={"Fetching all Settings"}
        noPadding
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Email"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <Email
          templates={templates}
          updateSetting={updateSetting}
          refresh={refresh}
          setting={settings[0]}
          sendTemplateEmail={sendTemplateEmail}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSettingPage);
