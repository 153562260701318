import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperTemplatePage from "../component/WrapperTemplatePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  templates: state.templates,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(Actions.getTemplates()),
  getTemplateById: (...args) => dispatch(Actions.getTemplateById(...args)),
  createTemplate: (...args) => dispatch(Actions.createTemplate(...args)),
  deleteTemplate: (...args) => dispatch(Actions.deleteTemplate(...args)),
  updateTemplate: (...args) => dispatch(Actions.updateTemplate(...args)),
});
class TemplatePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    templates: PropTypes.array,
    getTemplates: PropTypes.func,
    getTemplateById: PropTypes.func,
    createTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, templates } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: templates.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getTemplates } = this.props;
    await Promise.all([getTemplates()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      templates,
      getTemplateById,
      createTemplate,
      deleteTemplate,
      updateTemplate,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperTemplatePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        templates={templates}
        getTemplateById={getTemplateById}
        createTemplate={createTemplate}
        deleteTemplate={deleteTemplate}
        updateTemplate={updateTemplate}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
