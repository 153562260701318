import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import People from "@material-ui/icons/People";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircleTwoTone";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ChromePicker } from "react-color";
import fileDownload from "react-file-download";
// styles
import styles from "./styles";

class ServiceNameDetail extends Component {
  static propTypes = {
    classes: PropTypes.object,
    close: PropTypes.func,
    serviceName: PropTypes.object,
    deleteServiceName: PropTypes.func,
    updateServiceName: PropTypes.func,
    importServiceName: PropTypes.func,
    exportServiceName: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { serviceName } = this.props;
    const { UploadCenter } = this.context;
    this.state = {
      ...serviceName,
      index: 0,
      loading: false,
      method: 0,
    };

    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("callback"),
      "application/json"
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.init(nextProps.serviceName);
    }
  }

  init(serviceName) {
    //eslint-disable-line
    this.setState({
      ...serviceName,
      loading: false,
    });
  }

  getStepContent(index) {
    const { classes } = this.props;

    const { UploadCenter } = this.context;

    const { name, loading, deleteValidation, color } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <TextField
                id="name"
                fullWidth
                label="Name"
                className={classes.textField}
                value={name}
                onChange={this.handleChange("name")}
                onBlur={() => this.save("name")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("name");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <ChromePicker
                onChangeComplete={() => this.save("color")}
                color={color}
                onChange={(c) => {
                  this.setState({
                    color: c.hex,
                  });
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2} className={classes.step}>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Delete
              </Typography>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Enter the service's name to delete
                </Typography>
                <TextField
                  id="email"
                  variant="filled"
                  fullWidth
                  label={`${name}`}
                  className={classes.textField}
                  value={deleteValidation}
                  onChange={this.handleChange("deleteValidation")}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  style={{
                    opacity: deleteValidation !== name ? 0.5 : 1,
                  }}
                  size="small"
                  disabled={deleteValidation !== name}
                  onClick={this.deleteServiceName.bind(this)}
                >
                  Delete ServiceName
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Export
              </Typography>
              <Button
                variant="contained"
                size="small"
                onClick={this.handleExport.bind(this)}
              >
                Export Rules
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Import
              </Typography>
              <Button
                variant="contained"
                size="small"
                onClick={UploadCenter.openFileDialog}
              >
                Import Rules
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container>
            <Grid item>Unkown Step</Grid>
          </Grid>
        );
    }
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({
      [name]: value,
    });
  };

  async handleExport() {
    const { exportServiceName, serviceName } = this.props;
    const resp = await exportServiceName(serviceName.id);
    const json = resp.payload;
    if (json) {
      fileDownload(
        JSON.stringify(json, null, "\t"),
        `${serviceName.name}_${moment().format("YYYY-MM-DD HH:mm:ss")}.json`
      );
    }
  }

  userDidDrop(files) {
    const { importServiceName, serviceName, refresh } = this.props;
    const { NotificationCenter } = this.context;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt) => {
      const json = JSON.parse(evt.target.result);
      if (json) {
        importServiceName(serviceName.id, json).then((resp) => {
          if (resp.success) {
            NotificationCenter.sweetAlert({
              title: "Success",
              body: "Rules have been imported",
              success: true,
              timestamp: new Date().getTime(),
            });
            refresh();
          } else {
            NotificationCenter.sweetAlert({
              title: "Error",
              body: "Something went wrong. Check your JSON",
              error: true,
              timestamp: new Date().getTime(),
            });
          }
        });
      }
    };
  }

  async save(name, numeric) {
    const { updateServiceName, refresh, serviceName } = this.props;

    await updateServiceName(serviceName.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async deleteServiceName() {
    const { serviceName, deleteServiceName, close, refresh } = this.props;
    await deleteServiceName(serviceName.id);
    refresh();
    close();
  }

  render() {
    const { classes } = this.props;
    const { index, name, id } = this.state;

    return (
      <Grid container className={classes.container} direction="column">
        <Grid
          item
          style={{
            height: "calc(100vh - 57px)",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
          }}
        >
          <div
            style={{
              padding: 16,
              borderBottom: "solid 1px rgba(155, 155, 155, 0.3)",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <SwapHorizontalCircle fontSize="large" />
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  {name}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  #{id}
                </Typography>
              </Grid>
            </Grid>
          </div>
          {this.getStepContent(index)}
        </Grid>
        <Grid item>
          <BottomNavigation
            value={index}
            onChange={(event, newIndex) => {
              this.setState({ index: newIndex });
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction label="General" icon={<People />} />
            <BottomNavigationAction label="Danger" icon={<Warning />} />
          </BottomNavigation>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ServiceNameDetail);
