import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Portal from "@material-ui/core/Portal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ChromePicker } from "react-color";
// styles
import styles from "./styles";

class Email extends Component {
  static propTypes = {
    classes: PropTypes.object,
    setting: PropTypes.object,
    templates: PropTypes.array,
    sendTemplateEmail: PropTypes.array,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { setting } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      ...setting,
      loading: false,
    };
    MessageCenter.open("right");
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateSetting, refresh, setting } = this.props;

    await updateSetting(setting.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { classes, templates, sendTemplateEmail } = this.props;

    const { MessageCenter } = this.context;

    const {
      mailHost,
      baseURL,
      loading,
      replyEmail,
      mailPort,
      mailSSL,
      mailUser,
      mailPassword,
      mailSentName,
      mailSentEmail,
      mailLogo,
      primaryColor,
      secondaryColor,
      selectedTemplate,
      testEmail,
    } = this.state;

    return (
      <Grid container style={{ flexGrow: 1 }}>
        <Portal container={MessageCenter.getPortalContainer("right")}>
          <Grid
            container
            spacing={1}
            justify="center"
            alignItems="center"
            style={{
              width: 320,
              padding: 20,
              maxHeight: "100vh",
              overflowY: "scroll",
            }}
          >
            <Grid item xs={12}>
              <TextField
                id="replyEmail"
                fullWidth
                label="Reply Email"
                className={classes.textField}
                value={replyEmail}
                onChange={this.handleChange("replyEmail")}
                onBlur={() => this.save("replyEmail")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("replyEmail");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailLogo"
                fullWidth
                label="Mail Logo"
                className={classes.textField}
                value={mailLogo}
                onChange={this.handleChange("mailLogo")}
                onBlur={() => this.save("mailLogo")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailLogo");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailSentName"
                fullWidth
                label="Mail Sent Name"
                className={classes.textField}
                value={mailSentName}
                onChange={this.handleChange("mailSentName")}
                onBlur={() => this.save("mailSentName")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailSentName");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailSentEmail"
                fullWidth
                label="Mail Sent Email"
                className={classes.textField}
                value={mailSentEmail}
                onChange={this.handleChange("mailSentEmail")}
                onBlur={() => this.save("mailSentEmail")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailSentEmail");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Typography display="block" variant="body2">
                Primary Color
              </Typography>
              <ChromePicker
                onChangeComplete={() => this.save("primaryColor")}
                color={primaryColor}
                onChange={(c) => {
                  this.setState({
                    primaryColor: c.hex,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Typography display="block" variant="body2">
                Secondary Color
              </Typography>
              <ChromePicker
                onChangeComplete={() => this.save("secondaryColor")}
                color={secondaryColor}
                onChange={(c) => {
                  this.setState({
                    secondaryColor: c.hex,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="baseURL"
                fullWidth
                label="Base URL"
                className={classes.textField}
                value={baseURL}
                onChange={this.handleChange("baseURL")}
                onBlur={() => this.save("baseURL")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("baseURL");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailHost"
                fullWidth
                label="Mail Host"
                className={classes.textField}
                value={mailHost}
                onChange={this.handleChange("mailHost")}
                onBlur={() => this.save("mailHost")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailHost");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailPort"
                fullWidth
                label="Mail Port"
                className={classes.textField}
                value={mailPort}
                onChange={this.handleChange("mailPort", true)}
                onBlur={() => this.save("mailPort", true)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailPort", true);
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mailSSL}
                    onChange={() => {
                      this.setState((prevState) => ({
                        mailSSL: !prevState.mailSSL,
                      })).then(() => {
                        this.save("mailSSL");
                      });
                    }}
                    value="SSL"
                  />
                }
                label="SSL"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailUser"
                fullWidth
                label="Mail User"
                className={classes.textField}
                value={mailUser}
                onChange={this.handleChange("mailUser")}
                onBlur={() => this.save("mailUser")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailUser");
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mailPassword"
                fullWidth
                label="Mail Password"
                className={classes.textField}
                value={mailPassword}
                onChange={this.handleChange("mailPassword")}
                onBlur={() => this.save("mailPassword")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("mailPassword");
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Portal>
        <Grid item xs={12} style={{ background: "#212121" }}>
          <Grid container justify="center" style={{ padding: 20 }}>
            {templates &&
              templates.map((t) => (
                <Grid item key={t.code} style={{ padding: 8 }}>
                  <Chip
                    label={t.code}
                    style={{
                      background:
                        selectedTemplate && selectedTemplate.id === t.id
                          ? "#ff9800"
                          : "rgba(255,255,255,0.1)",
                      color: "white",
                    }}
                    onClick={() => this.setState({ selectedTemplate: t })}
                  />
                </Grid>
              ))}
            {selectedTemplate ? (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <InputBase
                  variant="filled"
                  id="testEmail"
                  placeholder="Send Test Email"
                  className={classes.textField}
                  value={testEmail}
                  onChange={this.handleChange("testEmail")}
                  disabled={loading}
                  style={{
                    background: "rgba(255,255,255,0.2)",
                    color: "white",
                    padding: "2px 6px 2px 6px",
                    borderRadius: 4,
                  }}
                />
                <Chip
                  label="Send"
                  style={{ marginLeft: 4 }}
                  onClick={() => {
                    sendTemplateEmail(selectedTemplate.id, { testEmail });
                  }}
                />
              </Grid>
            ) : (
              []
            )}
          </Grid>
          <Grid
            container
            style={{ width: "100%" }}
            alignItems="center"
            justify="center"
          >
            <Paper
              style={{
                width: "calc(100% - 100px)",
                maxWidth: 600,
                border: "solid 10px white",
              }}
            >
              <div style={{ background: "white", color: "black", padding: 20 }}>
                <Typography display="block" variant="h6" color="inherit">
                  {mailSentName} ({mailSentEmail})
                </Typography>
                <Typography display="block" color="inherit">
                  {selectedTemplate ? selectedTemplate.title : "Subject"}
                </Typography>
                <Typography display="block" color="inherit">
                  To: <span style={{ opacity: 0.7 }}>{mailUser}</span>
                </Typography>
                <Typography display="block" color="inherit">
                  Reply-To: <span style={{ opacity: 0.7 }}>{replyEmail}</span>
                </Typography>
              </div>
              <Grid
                style={{
                  background: primaryColor,
                  color: "black",
                  padding: 20,
                }}
                container
              >
                <Grid item xs={12}>
                  <Paper style={{ background: "white" }}>
                    <Grid container>
                      <Grid item style={{ padding: 20 }}>
                        <img src={mailLogo} style={{ height: 50 }} alt="mail" />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          background: "#F7F9FB",
                          padding: 20,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          display="block"
                          style={{ color: secondaryColor }}
                          variant="h4"
                        >
                          {selectedTemplate
                            ? selectedTemplate.title
                            : "Template Title"}
                        </Typography>
                        <Typography
                          display="block"
                          color="inherit"
                          variant="h6"
                        >
                          {selectedTemplate ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedTemplate.description,
                              }}
                            />
                          ) : (
                            "Template Description"
                          )}
                        </Typography>
                        <br />
                        <br />
                        <Button
                          style={{
                            background: primaryColor,
                            color: secondaryColor,
                          }}
                        >
                          {selectedTemplate
                            ? selectedTemplate.button
                            : "Button Text"}
                        </Button>
                        <br />
                        <br />
                        <Typography
                          display="block"
                          style={{ color: "#999999" }}
                          variant="caption"
                        >
                          You're receiving this email because of your account on{" "}
                          <span
                            style={{
                              color: secondaryColor,
                              fontWeight: 700,
                              textDecoration: "underline",
                            }}
                          >
                            {baseURL}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 20,
                    color: "white",
                  }}
                >
                  <Typography display="block" color="inherit">
                    <span style={{ color: secondaryColor }}>{baseURL} </span>|{" "}
                    {replyEmail}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Email);
