import { Chip } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
// components
import EventsList from "components/EventsList";
import FormEvent from "components/FormEvent";
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperEventsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //events
    events: PropTypes.array,
    getEvents: PropTypes.func,
    getEventById: PropTypes.func,
    createEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    updateEvent: PropTypes.func,
    testEvent: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedEvent: undefined,
      formEventOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      events,
      deleteEvent,
      updateEvent,
      getEventById,
      getEvents,
      history,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <EventsList
            history={history}
            api={{
              deleteEvent,
              updateEvent,
              getEventById,
              getEvents,
            }}
            events={events}
            onSelect={(selectedEvent) =>
              this.setState({
                selectedEvent,
                formEventOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createEvent,
      deleteEvent,
      updateEvent,
      refresh,
      testEvent,
    } = this.props;

    const { index, selectedEvent, formEventOpen } = this.state;

    return (
      <Page
        helmet={"Events"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formEventOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            style={{ position: "relative" }}
          >
            <Tab
              label="Events"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Chip
              label="Test OPA"
              size="small"
              icon={<SendIcon />}
              onClick={() => testEvent().then(refresh)}
              style={{ position: "absolute", top: 12, right: 8 }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormEvent
          open={formEventOpen}
          event={selectedEvent}
          close={() => {
            this.setState({
              formEventOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedEvent: undefined,
                }),
              200
            );
          }}
          createEvent={createEvent}
          deleteEvent={deleteEvent}
          updateEvent={updateEvent}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEventsPage);
