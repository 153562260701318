// material-ui
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config/env";
import { getJsonFromUrl, updateURLParameter } from "helpers";
import CopyLabel from "hooks/CopyLabel";
import EventDetail from "hooks/EventDetail";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const methodColor = {
  GET: "#4caf50",
  PUT: "#e91e63",
  DELETE: "#f44336",
  POST: "#2196f3",
};

class EventsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    events: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.func,
    getEvents: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  onSelect(selectedEvent) {
    const { onSelect } = this.props;
    this.setState({ selectedEvent });
    if (onSelect) {
      onSelect(selectedEvent);
    }
  }

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      selectedEvent: undefined,
      eventLoading: urlParams.eventID,
    };
  }

  componentWillReceiveProps(nextProps) {
    const urlParams = getJsonFromUrl(window.location);

    if (urlParams.eventID && this.state.selectedEvent === undefined) {
      this.setState({ eventLoading: true });
      nextProps.api.getEventById(Number(urlParams.eventID)).then((r) =>
        this.setState({
          selectedEvent: r.payload,
          eventLoading: false,
        })
      );
    }
  }

  render() {
    const { events, api, history } = this.props;
    const { eventLoading, selectedEvent } = this.state;

    const { NotificationCenter } = this.context;

    return (
      <AdvancedSearch
        history={history}
        rowCount={events.length}
        location={window.location}
        refresh={api.getEvents}
        keyword="events"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/unity/events/searchMetadata`}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Table
              data={events.sort(
                (a, b) =>
                  moment(b.dateCreated).valueOf() -
                  moment(a.dateCreated).valueOf()
              )}
              rowStyle={(d) => ({
                filter: d.resolved ? "saturate(0)" : undefined,
                opacity: d.resolved ? 0.4 : undefined,
                background:
                  selectedEvent && selectedEvent.id === d.id
                    ? "rgba(33,150,243, 0.1)"
                    : undefined,
              })}
              meta={[
                {
                  path: "method",
                  title: "Call",
                  numeric: false,
                  render: (t, d) => (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={d.method || "Unknown"}
                          size="small"
                          variant="outlined"
                          style={{
                            borderColor: methodColor[d.method],
                            color: methodColor[d.method],
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ fontFamily: "monospace", marginLeft: 8 }}
                        >
                          <CopyLabel label={d.resource} />
                        </Typography>
                      </div>
                    </>
                  ),
                },
                {
                  path: "status",
                  title: "Status",
                  numeric: false,
                },
                {
                  path: "dateCreated",
                  title: "Date",
                  numeric: false,
                  transform: (t) => moment(t).fromNow(),
                },
              ]}
              title={"Events"}
              onRowSelect={(e) => {
                this.setState({
                  selectedEvent: e,
                  eventDetailOpen: true,
                });
                history.push({
                  search: updateURLParameter("eventID", e.id),
                });
              }}
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={6}>
              {selectedEvent ? (
                <Paper style={{ padding: 20 }}>
                  <EventDetail
                    event={selectedEvent}
                    api={api}
                    NotificationCenter={NotificationCenter}
                    loading={eventLoading}
                    history={history}
                  />
                </Paper>
              ) : (
                []
              )}
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Dialog
            open={this.state.eventDetailOpen}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {selectedEvent || events[0] ? (
                <EventDetail
                  event={selectedEvent || events[0]}
                  api={api}
                  NotificationCenter={NotificationCenter}
                  loading={eventLoading}
                  history={history}
                />
              ) : (
                <Typography>Nothing to show for now</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => this.setState({ eventDetailOpen: false })}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(EventsList);
