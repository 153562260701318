import { networkAction } from "helpers/network/networkAction";

import {
  GET_GROUPS,
  GET_GROUP_ROLES,
  GET_GROUP_APPS,
  DELETE_GROUP_ROLE,
  CREATE_GROUP_ROLE,
  GET_GROUP_BY_ID,
  DELETE_GROUP,
  UPDATE_GROUP,
  CREATE_GROUP,
  DELETE_GROUP_APP,
  CREATE_GROUP_APP,
  GET_GROUP_ORGANIZATIONS,
  DELETE_GROUP_ORGANIZATION,
  CREATE_GROUP_ORGANIZATION,
} from "constants/groups";

import * as Api from "api";

export const getGroups = () => async (dispatch) =>
  networkAction(dispatch, GET_GROUPS, Api.getGroups, []);

export const getGroupRoles = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GROUP_ROLES, Api.getGroupRoles, [id]);

export const getGroupApps = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GROUP_APPS, Api.getGroupApps, [id]);

export const deleteGroupApp = (groupAppID) => async (dispatch) =>
  networkAction(dispatch, DELETE_GROUP_APP, Api.deleteGroupApp, [groupAppID]);

export const createGroupApp = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GROUP_APP, Api.createGroupApp, [body]);

export const createGroupRole = (id, body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GROUP_ROLE, Api.createGroupRole, [id, body]);

export const deleteGroupRole = (id, roleID) => async (dispatch) =>
  networkAction(dispatch, DELETE_GROUP_ROLE, Api.deleteGroupRole, [id, roleID]);

export const getGroupById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GROUP_BY_ID, Api.getGroupById, [id]);

export const deleteGroup = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_GROUP, Api.deleteGroup, [id]);

export const createGroup = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GROUP, Api.createGroup, [body]);

export const updateGroup = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GROUP, Api.updateGroup, [id, body]);

export const getGroupOrganizations = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GROUP_ORGANIZATIONS, Api.getGroupOrganizations, [
    id,
  ]);

export const createGroupOrganization = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_GROUP_ORGANIZATION,
    Api.createGroupOrganization,
    [id, body]
  );

export const deleteGroupOrganization = (groupAppID) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_GROUP_ORGANIZATION,
    Api.deleteGroupOrganization,
    [groupAppID]
  );
