import { Fab } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Portal from "@material-ui/core/Portal";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormTemplate from "components/FormTemplate";
// reactor
import Page from "components/Page";
import TemplateDetail from "components/TemplateDetail";
// custom
import TemplatesList from "components/TemplatesList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperTemplatePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    templates: PropTypes.array,
    createTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedTemplate: undefined,
      formTemplateOpen: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const { index } = this.state;
    const { templates } = this.props;
    const { MessageCenter } = this.context;

    switch (index) {
      case 0:
        return (
          <TemplatesList
            templates={templates}
            onSelect={(selectedTemplate) => {
              this.setState({
                selectedTemplate,
              });
              MessageCenter.open("right");
            }}
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createTemplate,
      deleteTemplate,
      updateTemplate,
      refresh,
    } = this.props;

    const { MessageCenter } = this.context;

    const { index, selectedTemplate, formTemplateOpen } = this.state;

    return (
      <Page
        helmet="Templates"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formTemplateOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Templates"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormTemplate
          open={formTemplateOpen}
          close={() => {
            this.setState({
              formTemplateOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedTemplate: undefined,
                }),
              200
            );
          }}
          createTemplate={createTemplate}
          deleteTemplate={deleteTemplate}
          updateTemplate={updateTemplate}
          refresh={refresh}
        />
        <Portal container={MessageCenter.getPortalContainer("right")}>
          {selectedTemplate ? (
            <ClickAwayListener onClickAway={() => console.log("onClickAway")}>
              <TemplateDetail
                template={selectedTemplate}
                createTemplate={createTemplate}
                deleteTemplate={deleteTemplate}
                updateTemplate={updateTemplate}
                refresh={refresh}
                history={history}
                close={() => {
                  this.setState({ selectedTemplate: undefined });
                  MessageCenter.close("right");
                }}
                refreshKey={selectedTemplate ? selectedTemplate.id : undefined}
              />
            </ClickAwayListener>
          ) : (
            []
          )}
        </Portal>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperTemplatePage);
