import { networkAction } from "helpers/network/networkAction";

import {
  GET_APPS,
  GET_APP_BY_ID,
  DELETE_APP,
  UPDATE_APP,
  CREATE_APP,
} from "constants/apps";

import * as Api from "api";

export const getApps = () => async (dispatch) =>
  networkAction(dispatch, GET_APPS, Api.getApps, []);

export const getAppById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_APP_BY_ID, Api.getAppById, [id]);

export const deleteApp = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_APP, Api.deleteApp, [id]);

export const createApp = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_APP, Api.createApp, [body]);

export const updateApp = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_APP, Api.updateApp, [id, body]);
