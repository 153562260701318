import { Fab } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Portal from "@material-ui/core/Portal";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormServiceName from "components/FormServiceName";
import Page from "components/Page";
import ServiceDetail from "components/ServiceDetail";
// components
import ServiceNamesList from "components/ServiceNamesList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperServiceNamePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    serviceNames: PropTypes.array,
    createServiceName: PropTypes.func,
    deleteServiceName: PropTypes.func,
    updateServiceName: PropTypes.func,
    importServiceName: PropTypes.func,
    exportServiceName: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedServiceName: undefined,
      formServiceNameOpen: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const { index } = this.state;
    const { serviceNames } = this.props;
    const { MessageCenter } = this.context;

    switch (index) {
      case 0:
        return (
          <ServiceNamesList
            serviceNames={serviceNames}
            onSelect={(selectedServiceName) => {
              this.setState({
                selectedServiceName,
              });
              MessageCenter.open("right");
            }}
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createServiceName,
      deleteServiceName,
      updateServiceName,
      importServiceName,
      exportServiceName,
      refresh,
    } = this.props;

    const { MessageCenter } = this.context;

    const { index, selectedServiceName, formServiceNameOpen } = this.state;

    return (
      <Page
        helmet="Service Services"
        loading={loading}
        loadingMessage={"Fetching all Services"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formServiceNameOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        }
      >
        {this.getStepContent()}
        <FormServiceName
          open={formServiceNameOpen}
          close={() => {
            this.setState({
              formServiceNameOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedServiceName: undefined,
                }),
              200
            );
          }}
          createServiceName={createServiceName}
          deleteServiceName={deleteServiceName}
          updateServiceName={updateServiceName}
          refresh={refresh}
        />
        <Portal container={MessageCenter.getPortalContainer("right")}>
          {selectedServiceName ? (
            <ClickAwayListener onClickAway={() => console.log("onClickAway")}>
              <ServiceDetail
                serviceName={selectedServiceName}
                createServiceName={createServiceName}
                deleteServiceName={deleteServiceName}
                updateServiceName={updateServiceName}
                importServiceName={importServiceName}
                exportServiceName={exportServiceName}
                refresh={refresh}
                history={history}
                close={() => {
                  this.setState({ selectedServiceName: undefined });
                  MessageCenter.close("right");
                }}
                refreshKey={
                  selectedServiceName ? selectedServiceName.id : undefined
                }
              />
            </ClickAwayListener>
          ) : (
            []
          )}
        </Portal>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperServiceNamePage);
