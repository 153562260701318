import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Edit from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/styles";
import { FormRole, FormRule } from "components";
// components
import FormServiceName from "components/FormServiceName";
import Page from "components/Page";
import { getJsonFromUrl } from "helpers";
import Rule from "hooks/Rule";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import fileDownload from "react-file-download";
// styles
import styles from "./styles";

class WrapperOpaPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    api: PropTypes.object,
    loading: PropTypes.bool,
    roles: PropTypes.array,
    serviceNames: PropTypes.array,
    rules: PropTypes.array,
  };

  static contextTypes = {
    UploadCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { UploadCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formServiceNameOpen: false,
      roles: [],
      rules: [],
      init: false,
    };

    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("callback"),
      "application/json"
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.init &&
      nextProps.serviceNames.length &&
      nextProps.roles.length
    ) {
      const urlParams = getJsonFromUrl(window.location);
      if (urlParams.serviceNameID) {
        this.selectServiceName(
          nextProps.serviceNames.find(
            (sn) => sn.id === Number(urlParams.serviceNameID)
          )
        )();
      }
      if (urlParams.roleID) {
        this.selectRole(
          nextProps.roles.find((sn) => sn.id === Number(urlParams.roleID))
        )();
      }
      this.state.init = true; //eslint-disable-line
    }
  }

  userDidDrop(files) {
    const { api, refresh } = this.props;
    const { selectServiceName } = this.state;
    const { NotificationCenter } = this.context;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt) => {
      const json = JSON.parse(evt.target.result);
      if (json) {
        api.importServiceName(selectServiceName.id, json).then((resp) => {
          if (resp.success) {
            NotificationCenter.sweetAlert({
              title: "Success",
              body: "Rules have been imported",
              success: true,
              timestamp: new Date().getTime(),
            });
            refresh();
          } else {
            NotificationCenter.sweetAlert({
              title: "Error",
              body: "Something went wrong. Check your JSON",
              error: true,
              timestamp: new Date().getTime(),
            });
          }
        });
      }
    };
  }

  selectServiceName = (serviceName) => () => {
    const { api, history } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    delete urlParams[""];
    delete urlParams.roleID;
    urlParams.serviceNameID = serviceName.id;
    const search = Object.keys(urlParams)
      .map(
        (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
      )
      .join("&");
    history.push({
      search,
    });
    this.setState({
      selectServiceName: serviceName,
      rules: [],
      roles: [],
    });

    api
      .getRoles([
        {
          name: "roles.serviceNameID",
          comparison: "eq",
          value: serviceName.id,
        },
      ])
      .then((r) => this.setState({ roles: r.payload }));
  };

  selectRole = (role) => async () => {
    const { api, history } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    delete urlParams[""];
    urlParams.roleID = role.id;
    const search = Object.keys(urlParams)
      .map(
        (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
      )
      .join("&");
    history.push({
      search,
    });
    this.setState({
      selectedRole: role,
    });

    const r = await api.getRules([
      {
        name: "roleID",
        comparison: "eq",
        value: role.id,
      },
    ]);

    if (r.success) {
      const { payload } = r;
      const rulesObj = {};
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          const element = payload[key];
          if (rulesObj[element.key]) rulesObj[element.key].push(element);
          else rulesObj[element.key] = [element];
        }
      }
      const rules = [];
      for (const key in rulesObj) {
        if (rulesObj.hasOwnProperty(key)) {
          const elements = rulesObj[key];
          rules.push({
            key,
            methods: elements,
          });
        }
      }
      this.setState({ rules });
    }
  };

  async handleExport(serviceName) {
    const { api } = this.props;
    const resp = await api.exportServiceName(serviceName.id);
    const json = resp.payload;
    if (json) {
      fileDownload(
        JSON.stringify(json, null, "\t"),
        `${serviceName.name}_${moment().format("YYYY-MM-DD HH:mm:ss")}.json`
      );
    }
  }

  render() {
    const { serviceNames, api, refresh } = this.props;
    const { UploadCenter } = this.context;
    const {
      roles,
      rules,
      selectServiceName,
      selectedRole,
      formServiceNameOpen,
    } = this.state;

    return (
      <Page helmet="Opa">
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            flex: 1,
            position: "relative",
          }}
        >
          <div
            style={{
              flex: 1,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              display: "flex",
              minHeight: 0,
              overflow: "auto",
              minWidth: 200,
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={0}
              style={{ padding: 8, position: "sticky", top: 0, zIndex: 999 }}
            >
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6" display="block">
                    Services
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => this.setState({ formServiceNameOpen: true })}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
            <Divider />
            <List dense style={{ paddingTop: 0, height: 0 }}>
              {serviceNames.map((s) => {
                return (
                  <ListItem
                    style={{ borderLeft: `solid 4px ${s.color}` }}
                    key={s.id}
                    button
                    selected={
                      selectServiceName && selectServiceName.id === s.id
                    }
                    divider
                    onClick={this.selectServiceName(s)}
                  >
                    <ListItemText primary={s.name} />
                    <ListItemSecondaryAction>
                      <IconButton size="small">
                        <CloudDownload
                          style={{ fontSize: 16 }}
                          onClick={() => this.handleExport(s)}
                        />
                      </IconButton>
                      <IconButton size="small">
                        <Edit
                          style={{ fontSize: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.setState({
                              selectedServiceName: s,
                              formServiceNameOpen: true,
                            });
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div
            style={{
              flex: 1,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              display: "flex",
              minHeight: 0,
              minWidth: 200,
              overflow: "auto",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={0}
              style={{ padding: 8, position: "sticky", top: 0, zIndex: 999 }}
            >
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6" display="block">
                    Roles
                  </Typography>
                </Grid>
                <Grid item>
                  {selectServiceName ? (
                    <IconButton
                      size="small"
                      onClick={UploadCenter.openFileDialog}
                    >
                      <BackupIcon />
                    </IconButton>
                  ) : (
                    []
                  )}
                  <IconButton
                    size="small"
                    onClick={() => this.setState({ formRoleOpen: true })}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
            <Divider />
            <List dense style={{ paddingTop: 0, height: 0 }}>
              {roles.map((r) => {
                return (
                  <ListItem
                    button
                    selected={selectedRole && selectedRole.id === r.id}
                    divider
                    onClick={this.selectRole(r)}
                  >
                    <ListItemText primary={r.name} />
                    <ListItemSecondaryAction>
                      <IconButton size="small">
                        <Edit
                          style={{ fontSize: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.setState({
                              selectedRole: r,
                              formRoleOpen: true,
                            });
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div
            style={{
              flex: 4,
              display: "flex",
              minHeight: 0,
              overflow: "auto",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={0}
              style={{ padding: 8, position: "sticky", top: 0, zIndex: 999 }}
            >
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6" display="block">
                    Rules
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => this.setState({ formRuleOpen: true })}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
            <Divider />
            <List dense style={{ paddingTop: 0, height: 0 }}>
              {rules.map((r) => (
                <Rule
                  key={r.id}
                  rule={r}
                  create={(m) =>
                    this.setState({
                      formRuleOpen: true,
                      suggestion: m,
                    })
                  }
                  edit={(m) =>
                    this.setState({
                      formRuleOpen: true,
                      selectedRule: m,
                    })
                  }
                  role={this.state.selectedRole}
                  api={api}
                  refresh={this.selectRole(this.state.selectedRole)}
                />
              ))}
            </List>
          </div>
        </Paper>
        <FormServiceName
          open={formServiceNameOpen}
          serviceName={this.state.selectedServiceName}
          close={() => {
            this.setState({
              formServiceNameOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedServiceName: undefined,
                }),
              200
            );
          }}
          exportServiceName={api.exportServiceName}
          createServiceName={api.createServiceName}
          deleteServiceName={api.deleteServiceName}
          updateServiceName={api.updateServiceName}
          refresh={refresh}
        />
        <FormServiceName
          open={formServiceNameOpen}
          serviceName={this.state.selectedServiceName}
          close={() => {
            this.setState({
              formServiceNameOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedServiceName: undefined,
                }),
              200
            );
          }}
          createServiceName={api.createServiceName}
          deleteServiceName={api.deleteServiceName}
          updateServiceName={api.updateServiceName}
          refresh={refresh}
        />
        <FormRole
          open={this.state.formRoleOpen}
          role={this.state.selectedRole}
          close={() => {
            this.setState({
              formRoleOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedRole: undefined,
                }),
              200
            );
          }}
          createRole={api.createRole}
          deleteRole={api.deleteRole}
          updateRole={api.updateRole}
          selectedServiceName={this.state.selectServiceName}
          serviceNames={serviceNames}
          refresh={() =>
            refresh().then(this.selectServiceName(this.state.selectServiceName))
          }
        />
        <FormRule
          open={this.state.formRuleOpen}
          rule={this.state.selectedRule}
          close={() => {
            this.setState({
              formRuleOpen: false,
              suggestion: undefined,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedRule: undefined,
                }),
              200
            );
          }}
          createRule={api.createRule}
          deleteRule={api.deleteRule}
          updateRule={api.updateRule}
          refresh={() =>
            refresh().then(this.selectRole(this.state.selectedRole))
          }
          suggestion={this.state.suggestion}
          role={selectedRole}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperOpaPage);
