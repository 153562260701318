import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Processing from "./processing.png";
// styles
import styles from "./styles";

class WrapperProcessingPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    opaReady: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };

    setInterval(this.checkOpa.bind(this), 5000);
  }

  async checkOpa() {
    const { opaReady } = this.props;
    const check = await opaReady();
    if (check.success) {
      const urlParams = getJsonFromUrl(window.location);
      const redirect = urlParams.redirect;
      window.location.replace(redirect);
    }
  }

  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Processing</title>
          <meta name="Processing" content="Processing page" />
        </Helmet>
        <div className="root noAppBar">
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ paddingTop: 50 }}
          >
            <Grid item xs={12} sm={4}>
              <Paper
                style={{
                  padding: 20,
                  textAlign: "center",
                }}
              >
                <img
                  alt={"Processing"}
                  src={Processing}
                  style={{ width: "100%" }}
                />
                <Typography
                  display="block"
                  variant="h6"
                  style={{ marginTop: 20 }}
                >
                  We are creating your account...
                </Typography>
                <br />
                <Typography display="block" variant="body">
                  This step lasts less than a minute. Keep this page open.
                  <br />
                  You will be redirected to the app once it is done.
                </Typography>
                <CircularProgress style={{ marginTop: 20 }} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperProcessingPage);
