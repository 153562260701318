// import PropTypes from 'prop-types';
// material-ui
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
// import { makeStyles } from "@material-ui/styles";
import { getJsonFromUrl } from "helpers";
import CopyLabel from "hooks/CopyLabel";
import React from "react";
// styles
// import styles from "./styles";

// const useStyles = makeStyles(styles);

export default function EventDetail({
  api,
  event,
  NotificationCenter,
  history,
  loading,
}) {
  const [comments, setComments] = React.useState(event.comments);
  const [resolved, setResolved] = React.useState(event.resolved);

  React.useEffect(() => {
    setComments(event.comments);
    setResolved(event.resolved);
  }, [event, loading]);

  // const classes = useStyles(styles);

  const save = (name, value, needRefresh) => async () => {
    await api.updateEvent(event.id, {
      [name]: value,
    });

    if (needRefresh) {
      const urlParams = getJsonFromUrl(window.location);
      api.getEvents(
        urlParams && urlParams.filters ? JSON.parse(urlParams.filters) : []
      );
    }
  };

  if (loading) {
    return (
      <Grid
        container
        style={{ textAlign: "left", minHeight: 540 }}
        justify="space-between"
        spacing={2}
      >
        <Grid item>
          <Skeleton style={{ width: 200, height: 30 }} variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 100 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 220 }} />
            <Skeleton variant="text" style={{ width: 198 }} />
          </div>
          <div style={{ display: "flex" }}>
            <Skeleton variant="text" style={{ width: 202 }} />
            <Skeleton variant="text" style={{ width: 100 }} />
          </div>
        </Grid>
        <Grid xs={12} style={{ marginBottom: 8 }}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <Skeleton variant="rect" style={{ borderRadius: 80, width: 80 }} />
          <Skeleton
            variant="rect"
            style={{ borderRadius: 80, width: 120, marginLeft: 80 }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      style={{ textAlign: "left" }}
      justify="space-between"
      spacing={2}
    >
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h3" style={{ fontWeight: 800 }}>
              {event.status}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Checkbox name="checkedC" checked={resolved} />}
              label="Resolved"
              onChange={(e) => {
                setResolved(e.target.checked);
                save("resolved", e.target.checked, true)();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          {event.method} <CopyLabel label={event.resource} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"Error Code: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.errorCode} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"Description: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.description} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"Module: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.module} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"Date Created: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.dateCreated} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"User UUID: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.userUUID} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"Ip Address: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.ipAddress} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"User Email"}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.userEmail} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"User Agent: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.userAgent} />
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ width: 120 }} variant="caption">
            {"ID: "}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 4, maxWidth: "calc(100% - 120px)" }}
          >
            <CopyLabel label={event.id} />
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} style={{ marginBottom: 8 }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          onBlur={save("comments", comments, false)}
          label="Comments"
          style={{ background: "rgba(255,235,59,0.1)" }}
          fullWidth
          multiline
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Stack Trace:</Typography>
        <div
          style={{
            background: "#212121",
            fontFamily: "monospace",
            padding: 8,
            color: "white",
            fontSize: 10,
            borderRadius: 4,
          }}
        >
          {event.stack || "None"}
        </div>
      </Grid>
    </Grid>
  );
}
