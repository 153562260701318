import { PROCESSING } from "constants/routes";
import { checkAccess } from "helpers/auth";
import ProcessingPage from "./container/ProcessingPage";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: PROCESSING,
    withSidebar: false,
    withAppBar: false,
    component: ProcessingPage,
  };
}
