// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperApplicationPage from "../component/WrapperApplicationPage";

const mapStateToProps = (state) => ({
  apps: state.apps,
});

const mapDispatchToProps = (dispatch) => ({
  getApps: () => dispatch(Actions.getApps()),
  getAppById: (...args) => dispatch(Actions.getAppById(...args)),
  createApp: (...args) => dispatch(Actions.createApp(...args)),
  deleteApp: (...args) => dispatch(Actions.deleteApp(...args)),
  updateApp: (...args) => dispatch(Actions.updateApp(...args)),
});
class ApplicationPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    apps: PropTypes.array,
    getApps: PropTypes.func,
    getAppById: PropTypes.func,
    createApp: PropTypes.func,
    deleteApp: PropTypes.func,
    updateApp: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, apps } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: apps.length === 0,
    };
    this.refresh();
  }

  async refresh() {
    const { getApps } = this.props;
    await Promise.all([getApps()]);

    this.setState({ loading: false });
  }

  render() {
    const { apps, getAppById, createApp, deleteApp, updateApp } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperApplicationPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        applications={apps}
        getAppById={getAppById}
        createApp={createApp}
        deleteApp={deleteApp}
        updateApp={updateApp}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPage);
