import { networkAction } from "helpers/network/networkAction";

import {
  GET_ACCESS_TOKENS,
  GET_ACCESS_TOKEN_BY_ID,
  DELETE_ACCESS_TOKEN,
  UPDATE_ACCESS_TOKEN,
  CREATE_ACCESS_TOKEN,
} from "constants/accessTokens";

import * as Api from "api";

export const getAccessTokens = () => async (dispatch) =>
  networkAction(dispatch, GET_ACCESS_TOKENS, Api.getAccessTokens, []);

export const getAccessTokenById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ACCESS_TOKEN_BY_ID, Api.getAccessTokenById, [id]);

export const deleteAccessToken = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ACCESS_TOKEN, Api.deleteAccessToken, [id]);

export const createAccessToken = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ACCESS_TOKEN, Api.createAccessToken, [body]);

export const updateAccessToken = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ACCESS_TOKEN, Api.updateAccessToken, [
    id,
    body,
  ]);
