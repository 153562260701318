// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TemplatesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    templates: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedTemplate) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedTemplate);
    }
  }

  render() {
    const { templates } = this.props;

    return (
      <Table
        data={templates}
        meta={[
          {
            path: "title",
            title: "Title",
            numeric: false,
          },
          {
            path: "code",
            title: "Code",
            numeric: false,
          },
          {
            path: "button",
            title: "Button",
            numeric: false,
          },
        ]}
        title={"Templates"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(TemplatesList);
