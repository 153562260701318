// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class RolesList extends React.Component {
  static propTypes = {
    roles: PropTypes.array,
    onEdit: PropTypes.func,
    onRules: PropTypes.func,
    selectedRole: PropTypes.object,
  };

  onEditClick(selectedRole) {
    const { onEdit } = this.props;
    if (onEdit) {
      onEdit(selectedRole);
    }
  }

  onRulesClick(selectedRole) {
    const { onRules } = this.props;
    if (onRules) {
      onRules(selectedRole);
    }
  }

  render() {
    const { roles, onEdit, selectedRole } = this.props;

    return (
      <Table
        data={roles}
        rowStyle={(d) => ({
          background:
            selectedRole && selectedRole.id === d.id
              ? "rgba(33,150,243, 0.1)"
              : undefined,
        })}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "serviceName",
            title: "Service Name",
            numeric: false,
            render: (t, d) => (
              <span
                style={{
                  background: d.serviceColor,
                  color: "white",
                  borderRadius: 12,
                  padding: "2px 8px 2px 8px",
                }}
              >
                {t}
              </span>
            ),
          },
        ]}
        title={"Roles"}
        onRowSelect={onEdit}
      />
    );
  }
}

export default withStyles(styles)(RolesList);
