import { Fab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormRule from "components/FormRule";
// custom
import Loading from "components/Loading";
import RulesList from "components/RulesList";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogRulesList extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    close: PropTypes.func,
    role: PropTypes.object,

    // rules
    getRules: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,
    createRule: PropTypes.func,
  };

  state = {
    loading: true,
    formRuleOpen: false,
    selectedRule: undefined,
  };

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;

    if (nextProps.open && !open) {
      const { role } = nextProps;
      if (role && role.id) {
        this.refresh(role);
      }
    }
  }

  async refresh(role) {
    const { getRules } = this.props;
    this.setState({ loading: true });
    const filters = [
      {
        name: "roleID",
        comparison: "eq",
        value: role.id,
      },
    ];

    const resp = await getRules(`?filters=${JSON.stringify(filters)}`);
    if (resp.success) {
      const rules = resp.payload;
      this.setState({ rules, loading: false });
    }
  }

  render() {
    const {
      classes,
      open,
      close,
      role,
      createRule,
      deleteRule,
      updateRule,
    } = this.props;

    const { loading, rules, formRuleOpen, selectedRule } = this.state;

    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={close} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              display="block"
              variant="h6"
              color="inherit"
              className={classes.flex}
            >
              {`${role ? role.name : ""} Rules`}
            </Typography>
            <Button color="inherit" onClick={close}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          {loading ? (
            <Loading loading={loading} message={"Fetching rules"} />
          ) : (
            <RulesList
              rules={rules}
              onSelect={(r) =>
                this.setState({ selectedRule: r, formRuleOpen: true })
              }
            />
          )}
        </div>
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formRuleOpen: true })}
        >
          <AddIcon />
        </Fab>
        <FormRule
          open={formRuleOpen}
          rule={selectedRule}
          close={() => {
            this.setState({
              formRuleOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedRule: undefined,
                }),
              200
            );
          }}
          createRule={createRule}
          deleteRule={deleteRule}
          updateRule={updateRule}
          refresh={() => this.refresh(role)}
          role={role}
        />
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogRulesList);
