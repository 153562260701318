import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "account";
const templates = `${REACT_APP_API_PREFIX}/${service}/templates`;

export function getTemplates() {
  const url = `${templates}`;
  return {
    method: GET,
    url,
  };
}

export function getTemplateById(id) {
  const url = `${templates}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTemplate(id, body) {
  const url = `${templates}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function sendTemplateEmail(id, body) {
  const url = `${templates}/${id}/send`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTemplate(id) {
  const url = `${templates}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTemplate(body) {
  const url = `${templates}`;
  return {
    method: POST,
    url,
    body,
  };
}
