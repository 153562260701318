// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperOrganizationPage from "../component/WrapperOrganizationPage";

const mapStateToProps = (state) => ({
  organizations: state.organizations,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateOrganizationUser: (...args) =>
    dispatch(Actions.updateOrganizationUser(...args)),
  createOrganizationUser: (...args) =>
    dispatch(Actions.createOrganizationUser(...args)),
  getOrganizations: (...args) => dispatch(Actions.getOrganizations(...args)),
  getOrganizationById: (...args) =>
    dispatch(Actions.getOrganizationById(...args)),
  createOrganization: (...args) =>
    dispatch(Actions.createOrganization(...args)),
  deleteOrganization: (...args) =>
    dispatch(Actions.deleteOrganization(...args)),
  deleteOrganizationUser: (...args) =>
    dispatch(Actions.deleteOrganizationUser(...args)),
  updateOrganization: (...args) =>
    dispatch(Actions.updateOrganization(...args)),
  getOrganizationUsers: (...args) =>
    dispatch(Actions.getOrganizationUsers(...args)),
  addUserToOrganization: (...args) =>
    dispatch(Actions.addUserToOrganization(...args)),
});
class OrganizationPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    organizations: PropTypes.array,
    getOrganizations: PropTypes.func,
    getOrganizationById: PropTypes.func,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    updateOrganizationUser: PropTypes.func,
    createOrganizationUser: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, organizations } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: organizations.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getOrganizations } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getOrganizations(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      organizations,
      getOrganizationById,
      createOrganization,
      deleteOrganization,
      updateOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      createOrganizationUser,
      getOrganizations,
      updateOrganizationUser,
      addUserToOrganization,
      user,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperOrganizationPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        user={user}
        getOrganizations={getOrganizations}
        organizations={organizations}
        getOrganizationById={getOrganizationById}
        updateOrganizationUser={updateOrganizationUser}
        createOrganization={createOrganization}
        deleteOrganization={deleteOrganization}
        updateOrganization={updateOrganization}
        getOrganizationUsers={getOrganizationUsers}
        deleteOrganizationUser={deleteOrganizationUser}
        createOrganizationUser={createOrganizationUser}
        addUserToOrganization={addUserToOrganization}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPage);
