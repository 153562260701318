import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperServiceNamePage from "../component/WrapperServiceNamePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  serviceNames: state.serviceNames,
});

const mapDispatchToProps = (dispatch) => ({
  getServiceNames: () => dispatch(Actions.getServiceNames()),
  getServiceNameById: (...args) =>
    dispatch(Actions.getServiceNameById(...args)),
  createServiceName: (...args) => dispatch(Actions.createServiceName(...args)),
  deleteServiceName: (...args) => dispatch(Actions.deleteServiceName(...args)),
  updateServiceName: (...args) => dispatch(Actions.updateServiceName(...args)),
  importServiceName: (...args) => dispatch(Actions.importServiceName(...args)),
  exportServiceName: (...args) => dispatch(Actions.exportServiceName(...args)),
});
class ServiceNamePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    serviceNames: PropTypes.array,
    getServiceNames: PropTypes.func,
    getServiceNameById: PropTypes.func,
    createServiceName: PropTypes.func,
    deleteServiceName: PropTypes.func,
    updateServiceName: PropTypes.func,
    importServiceName: PropTypes.func,
    exportServiceName: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, serviceNames } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: serviceNames.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getServiceNames } = this.props;
    await Promise.all([getServiceNames()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      serviceNames,
      getServiceNameById,
      createServiceName,
      deleteServiceName,
      updateServiceName,
      importServiceName,
      exportServiceName,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperServiceNamePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        serviceNames={serviceNames}
        getServiceNameById={getServiceNameById}
        createServiceName={createServiceName}
        deleteServiceName={deleteServiceName}
        updateServiceName={updateServiceName}
        importServiceName={importServiceName}
        exportServiceName={exportServiceName}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceNamePage);
