import { Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
// components
import Page from "components/Page";
import { REACT_APP_CLIENT_NAME, REACT_APP_LOGO } from "config";
import { JOIN_ORGANIZATION, SIGNIN } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Confetti from "react-confetti";
// styles
import styles from "./styles";

class WrapperJoinOrganizationPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    theme: PropTypes.object,
    urlParams: PropTypes.object,
    user: PropTypes.object,
    organization: PropTypes.object,
    history: PropTypes.object,
    joinOrganization: PropTypes.func,
    token: PropTypes.string,
  };

  static contextTypes = {
    openSignup: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      joined: false,
    };
  }

  async join() {
    const { joinOrganization, token } = this.props;
    const r = await joinOrganization({
      token,
    });
    if (r.success) {
      this.setState({ joined: true });
    }
  }

  render() {
    const { theme, user, history, loading, token, organization } = this.props;
    const { openSignup } = this.context;
    const { answer, joined } = this.state;

    return (
      <Page helmet="JoinOrganization" loading={loading} noPadding>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            background: "#F1F5F9",
            color: "#37474f",
          }}
        >
          <div
            style={{
              textAlign: "center",
              background: "white",
              borderRadius: 12,
              overflow: "hidden",
              maxWidth: 600,
              width: "100%",
              boxShadow:
                "0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
            }}
          >
            <div
              style={{
                background: theme.palette.secondary.A700,
                padding: 20,
              }}
            >
              <img src={REACT_APP_LOGO} style={{ height: 70 }} />
            </div>
            {!joined ? (
              <div style={{ padding: 20 }}>
                {user && user.uuid ? (
                  <Grid
                    container
                    style={{ marginTop: 20 }}
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" display="block">
                        You have been invited to join the group
                      </Typography>
                      {organization && organization.logo ? (
                        <img
                          src={organization.logo}
                          alt="logo"
                          style={{ height: 65, marginTop: 20 }}
                        />
                      ) : (
                        []
                      )}
                      <Typography variant="h6" display="block">
                        <b>{organization && organization.name}</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          background: "#4caf50",
                          color: "white",
                        }}
                        onClick={this.join.bind(this)}
                      >
                        Accept
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          background: "#f44336",
                          color: "white",
                        }}
                        onClick={() => history.push("/")}
                      >
                        Ignore
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    style={{ marginTop: 20 }}
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} style={{ textAlign: "none" }}>
                      <Typography variant="h6">
                        Do you already have an account with <br />
                        <b>{REACT_APP_CLIENT_NAME}</b>?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{
                          color: answer === 1 && "white",
                          background: answer === 1 && "#2196f3",
                        }}
                        onClick={() => this.setState({ answer: 1 })}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid item onClick={() => this.setState({ answer: 2 })}>
                      <Button
                        variant="contained"
                        style={{
                          color: answer === 2 && "white",
                          background: answer === 2 && "#2196f3",
                        }}
                      >
                        No
                      </Button>
                    </Grid>
                    {answer === 1 ? (
                      <Grid item xs={12}>
                        <Alert severity="info">
                          Please sign in first in order to join
                        </Alert>
                        <br />
                        <Button
                          style={{
                            width: "100%",
                            background: "#2196f3",
                            color: "white",
                          }}
                          onClick={() => {
                            const { localStorage } = window;
                            localStorage.redirectUrl = `${JOIN_ORGANIZATION}?token=${token}`;
                            history.push(
                              `${SIGNIN}?email=${organization.userEmail}`
                            );
                          }}
                        >
                          Sign In
                        </Button>
                      </Grid>
                    ) : (
                      []
                    )}

                    {answer === 2 ? (
                      <Grid item xs={12}>
                        <Alert severity="info">
                          Please create your account in order to join
                        </Alert>
                        <br />
                        <Button
                          style={{
                            width: "100%",
                            background: "#2196f3",
                            color: "white",
                          }}
                          onClick={() =>
                            openSignup(this.props.token, organization.userEmail)
                          }
                        >
                          Create an Account
                        </Button>
                      </Grid>
                    ) : (
                      []
                    )}
                  </Grid>
                )}
              </div>
            ) : (
              <div style={{ padding: 20 }}>
                <span role="img" style={{ fontSize: 52 }}>
                  🥳
                </span>
                <Typography variant="h3">Congratulation!</Typography>
                <Typography variant="body2" display="block">
                  You are now part of the {organization && organization.name}{" "}
                  Organization
                </Typography>
                <br />
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    background: "#2196f3",
                    color: "white",
                  }}
                  onClick={() => {
                    if (user.defaultAppURL)
                      window.location.replace(`https://${user.defaultAppURL}`);
                    else history.push("/");
                  }}
                >
                  Let's Go!
                </Button>
              </div>
            )}
            <div style={{ padding: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Question? Email:{" "}
                <a
                  style={{ color: "inherit" }}
                  href={`mailto:${organization && organization.ownerEmail}`}
                >
                  {organization && organization.ownerEmail}
                </a>
              </Typography>
            </div>
          </div>
          {joined ? (
            <div style={{ zIndex: 1301, position: "fixed", top: 0, left: 0 }}>
              <Confetti
                recycle={false}
                gravity={0.2}
                tweenDuration={4000}
                numberOfPieces={200}
              />
            </div>
          ) : (
            []
          )}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  WrapperJoinOrganizationPage
);
