const drawerWidth = 240;

export default (theme) => ({
  drawerContainer: {
    height: "calc(100% - 40px)",
    background: theme.palette.background.paper,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    background: theme.palette.background.paper,
    height: "100%",
    flexGrow: 1,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7,
    },
  },
});
