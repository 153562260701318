// routes
import Apps from "@material-ui/icons/Apps";
import GavelIcon from "@material-ui/icons/Gavel";
import GroupWorkIcon from "@material-ui/icons/GroupWorkTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import Public from "@material-ui/icons/PublicTwoTone";
import VpnLockIcon from "@material-ui/icons/VpnLockTwoTone";
import {
  APPS,
  EVENTS,
  GROUP,
  OPA,
  ORGANIZATIONS,
  USERS,
} from "constants/routes";

export const menu = [
  {
    icon: Apps,
    label: "Apps",
    path: APPS,
  },
  {
    icon: People,
    label: "Users",
    path: USERS,
  },
  {
    icon: GroupWorkIcon,
    label: "Groups",
    path: GROUP,
    divider: true,
  },
  {
    icon: Public,
    label: "Organizations",
    path: ORGANIZATIONS,
    divider: true,
  },
  {
    icon: GavelIcon,
    label: "OPA",
    path: OPA,
    divider: true,
  },
  {
    icon: VpnLockIcon,
    label: "Unity",
    path: EVENTS,
    divider: true,
  },
  // {
  //   icon: PanTool,
  //   label: 'Access Rights',
  //   path: SERVICE_NAME,
  //   divider: true,
  //   nested: [
  //     {
  //       icon: SwapHorizontalCircle,
  //       label: 'Service Names',
  //       path: SERVICE_NAME,
  //       divider: true,
  //     },
  //     {
  //       icon: Group,
  //       label: 'Groups',
  //       path: GROUP,
  //     },
  //     {
  //       icon: Security,
  //       label: 'Roles',
  //       path: ROLE,
  //     },
  //   ],
  // },
];
