import { APPLICATIONS } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import ApplicationPage from "./container/ApplicationPage";

export default function getApplicationRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: APPLICATIONS,
    withSidebar: true,
    withAppBar: true,
    component: ApplicationPage,
  };
}
