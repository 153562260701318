import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableRemove extends Component {
  static propTypes = {
    datum: PropTypes.object,
    onRemove: PropTypes.func,
  };

  render() {
    const { datum, onRemove } = this.props;

    return (
      <IconButton onClick={() => onRemove(datum)} aria-label="Delete">
        <Close />
      </IconButton>
    );
  }
}

export default withStyles(styles)(TableRemove);
