import { errorNotification } from "actions/errors";
import { META_ONLY, NO_401_REDIRECT } from "constants/methods";
import { SIGNIN } from "constants/routes";
import Fetcher from "helpers/network/network";

const fetcher = new Fetcher();

export async function networkAction(dispatch, action, apiCall, args) {
  let call;
  let redirect401 = true;
  if (args[0] === META_ONLY) {
    call = apiCall("");
    return call;
  }

  if (args[0] === NO_401_REDIRECT) {
    redirect401 = false;
  }

  call = apiCall(...args);

  dispatch({ type: `${action}_LOADING` });
  const resp = await fetcher[call.method]({ url: call.url, body: call.body });
  let json;
  try {
    json = await resp.json();
  } catch (e) {
    console.log(e);
  }

  if (resp.status === 401 && redirect401) {
    debugger;
    window.location.replace(`${SIGNIN}${window.location.search}`);
  }

  if (resp.status !== 200 && resp.status !== 204) {
    dispatch(errorNotification(json));
    const ac = {
      type: `${action}_ERROR`,
      payload: json,
      success: false,
    };
    dispatch(ac);
    return ac;
  }

  const ac = {
    type: `${action}_SUCCESS`,
    payload: json,
    success: true,
  };
  dispatch(ac);
  return ac;
}
