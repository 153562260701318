import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import TableMethod from "components/TableMethod";
// constants
import { DELETE, GET, POST, PUT } from "constants/methods";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Rule extends Component {
  static propTypes = {
    classes: PropTypes.object,
    rule: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { rule } = this.props;
    this.state = {
      ...rule,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateRule, refresh, rule } = this.props;

    await updateRule(rule.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async deleteRule() {
    const { deleteRule, rule, refresh } = this.props;
    await deleteRule(rule.id);
    refresh();
  }

  render() {
    const { classes } = this.props;
    const { method, path } = this.state;

    return (
      <div className={classes.container}>
        <Close
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            cursor: "pointer",
            height: 15,
            zIndex: 999,
          }}
          onClick={this.deleteRule.bind(this)}
        />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role-simple">Method</InputLabel>
              <Select
                disableUnderline
                value={method}
                onChange={(e) => {
                  this.setState((prevState) => ({
                    method: !prevState.method,
                  })).then(() => {
                    this.save("method");
                  });
                }}
                inputProps={{
                  name: "method",
                  id: "method-simple",
                }}
              >
                <MenuItem key={GET} value={GET.toUpperCase()}>
                  <TableMethod datum={{ method: GET }} />
                </MenuItem>
                <MenuItem key={POST} value={POST.toUpperCase()}>
                  <TableMethod datum={{ method: POST }} />
                </MenuItem>
                <MenuItem key={PUT} value={PUT.toUpperCase()}>
                  <TableMethod datum={{ method: PUT }} />
                </MenuItem>
                <MenuItem key={DELETE} value={DELETE.toUpperCase()}>
                  <TableMethod datum={{ method: DELETE }} />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label={"Path"}
              className={classes.textField}
              value={path}
              onChange={this.handleChange("path")}
              onBlur={() => this.save("path")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("path");
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Rule);
