import { Fab } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Portal from "@material-ui/core/Portal";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ApplicationDetail from "components/ApplicationDetail";
// components
import ApplicationsList from "components/ApplicationsList";
import FormApplication from "components/FormApplication";
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperApplicationPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    applications: PropTypes.array,
    createApp: PropTypes.func,
    deleteApp: PropTypes.func,
    updateApp: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedApplication: undefined,
      formApplicationOpen: false,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const { index } = this.state;
    const { applications } = this.props;
    const { MessageCenter } = this.context;

    switch (index) {
      case 0:
        return (
          <div
            style={{ maxWidth: 1500, margin: "auto", width: "100%", flex: 1 }}
          >
            <ApplicationsList
              applications={applications}
              onSelect={(selectedApplication) => {
                this.setState({
                  selectedApplication,
                });
                MessageCenter.open("right");
              }}
            />
          </div>
        );
      default:
    }

    return [];
  }

  render() {
    const {
      history,
      loading,
      createApp,
      deleteApp,
      updateApp,
      refresh,
    } = this.props;

    const { MessageCenter } = this.context;

    const { selectedApplication, formApplicationOpen } = this.state;

    return (
      <Page
        helmet="Applications"
        loading={loading}
        loadingMessage={"Fetching all Applications"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formApplicationOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
      >
        {this.getStepContent()}
        <FormApplication
          open={formApplicationOpen}
          close={() => {
            this.setState({
              formApplicationOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedApplication: undefined,
                }),
              200
            );
          }}
          createApp={createApp}
          deleteApp={deleteApp}
          updateApp={updateApp}
          refresh={refresh}
        />
        <Portal container={MessageCenter.getPortalContainer("right")}>
          {selectedApplication ? (
            <ClickAwayListener onClickAway={() => console.log("onClickAway")}>
              <ApplicationDetail
                application={selectedApplication}
                createApplication={createApp}
                deleteApplication={deleteApp}
                updateApplication={updateApp}
                refresh={refresh}
                history={history}
                close={() => {
                  this.setState({ selectedApplication: undefined });
                  MessageCenter.close("right");
                }}
                refreshKey={
                  selectedApplication ? selectedApplication.id : undefined
                }
              />
            </ClickAwayListener>
          ) : (
            []
          )}
        </Portal>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperApplicationPage);
