import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperRolePage from "../component/WrapperRolePage";

const mapStateToProps = (state) => ({
  roles: state.roles,
  serviceNames: state.serviceNames,
});

const mapDispatchToProps = (dispatch) => ({
  getServiceNames: () => dispatch(Actions.getServiceNames()),
  getRoles: () => dispatch(Actions.getRoles()),
  getRoleById: (...args) => dispatch(Actions.getRoleById(...args)),
  createRole: (...args) => dispatch(Actions.createRole(...args)),
  deleteRole: (...args) => dispatch(Actions.deleteRole(...args)),
  updateRole: (...args) => dispatch(Actions.updateRole(...args)),
  getRules: (...args) => dispatch(Actions.getRules(...args)),
  deleteRule: (...args) => dispatch(Actions.deleteRule(...args)),
  updateRule: (...args) => dispatch(Actions.updateRule(...args)),
  createRule: (...args) => dispatch(Actions.createRule(...args)),
});
class RolePage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // serviceNames
    getServiceNames: PropTypes.func,
    serviceNames: PropTypes.array,

    // roles
    roles: PropTypes.array,
    getRoles: PropTypes.func,
    getRoleById: PropTypes.func,
    createRole: PropTypes.func,
    deleteRole: PropTypes.func,
    updateRole: PropTypes.func,

    // rules
    getRules: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,
    createRule: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, roles } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: roles.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getRoles, getServiceNames } = this.props;
    await Promise.all([getRoles(), getServiceNames()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      roles,
      getRoleById,
      createRole,
      deleteRole,
      updateRole,
      history,
      getRules,
      deleteRule,
      updateRule,
      createRule,
      serviceNames,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperRolePage
        refresh={this.refresh.bind(this)}
        urlParams={urlParams}
        history={history}
        loading={loading}
        roles={roles}
        serviceNames={serviceNames}
        getRoleById={getRoleById}
        createRole={createRole}
        deleteRole={deleteRole}
        updateRole={updateRole}
        getRules={getRules}
        deleteRule={deleteRule}
        updateRule={updateRule}
        createRule={createRule}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RolePage);
